import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import avatar1 from "../../assets/imgs/people/avatar1.jpg";
import avatar2 from "../../assets/imgs/people/avatar2.jpg";
import avatar3 from "../../assets/imgs/people/avatar3.jpg";
import avatar4 from "../../assets/imgs/people/avatar4.jpg";
import { Link } from "react-router-dom";

function PageBuyerslist(){
    return(
        <>
        
        <Sidebar/>
        <main className="main-wrap">
        <Header/>

        <section className="content-main">
        <div className="content-header">
          <h2 className="content-title">Buyers list</h2>
          <div><Link className="btn btn-primary" to="/pagebuyersadd"><i className="material-icons md-plus"></i> Create new</Link></div>
        </div>
        <div className="card mb-4">
          <header className="card-header">
            <Row className="gx-3">
              <Col lg={4} md={6} className="me-auto">
                <input className="form-control" type="text" placeholder="Search..."/>
              </Col>
              <Col lg={2} md={3} xs={6}>
                <select className="form-select">
                  <option>Status</option>
                  <option>Active</option>
                  <option>Disabled</option>
                  <option>Show all</option>
                </select>
              </Col>
              <Col lg={2} md={3} xs={6}>
                <select className="form-select">
                  <option>Show 20</option>
                  <option>Show 30</option>
                  <option>Show 40</option>
                </select>
              </Col>
            </Row>
          </header>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Buyer</th>
                    <th>Contact No</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Registered</th>
                    <th className="text-end"> Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><a className="itemside" href="#">
                        <div className="info pl-3">
                          <h6 className="mb-0 title">Eleanor Pena</h6><small className="text-muted">Buyer ID: #439</small>
                        </div></a></td>
                    <td>9874563210</td>
                    <td>eleanor2022@example.com</td>
                    <td><span className="badge rounded-pill alert-success">Active</span></td>
                    <td>08.07.2022</td>
                    <td className="text-end"><Link className="btn btn-sm btn-brand rounded font-sm mt-15" to="/pagebuyerdetail">View details</Link></td>
                  </tr>
                  <tr>
                    <td><a className="itemside" href="#">
                        <div className="info pl-3">
                          <h6 className="mb-0 title">Mary Monasa</h6><small className="text-muted">Buyer ID: #129</small>
                        </div></a></td>
                    <td>9874563210</td>
                    <td>monalisa@example.com</td>
                    <td><span className="badge rounded-pill alert-success">Active</span></td>
                    <td>11.07.2022</td>
                    <td className="text-end"><Link className="btn btn-sm btn-brand rounded font-sm mt-15" to="/pagebuyerdetail">View details</Link></td>
                  </tr>
                  <tr>
                    <td><a className="itemside" href="#">
                        <div className="info pl-3">
                          <h6 className="mb-0 title">Jonatan Ive</h6><small className="text-muted">Buyer ID: #400</small>
                        </div></a></td>
                    <td>9874563210</td>
                    <td>mrjohn@example.com</td>
                    <td><span className="badge rounded-pill alert-success">Active</span></td>
                    <td>08.07.2022</td>
                    <td className="text-end"><Link className="btn btn-sm btn-brand rounded font-sm mt-15" to="/pagebuyerdetail">View details</Link></td>
                  </tr>
                  <tr>
                    <td><a className="itemside" href="#">
                        <div className="info pl-3">
                          <h6 className="mb-0 title">Eleanor Pena</h6><small className="text-muted">Buyer ID: #439</small>
                        </div></a></td>
                    <td>9874563210</td>
                    <td>eleanor2022@example.com</td>
                    <td><span className="badge rounded-pill alert-danger">Inactive</span></td>
                    <td>08.07.2022</td>
                    <td className="text-end"><Link className="btn btn-sm btn-brand rounded font-sm mt-15" to="/pagebuyerdetail">View details</Link></td>
                  </tr>
                  <tr>
                    <td><a className="itemside" href="#">
                        <div className="info pl-3">
                          <h6 className="mb-0 title">Albert Pushkin</h6><small className="text-muted">Buyer ID: #439</small>
                        </div></a></td>
                    <td>9874563210</td>
                    <td>someone@mymail.com</td>
                    <td><span className="badge rounded-pill alert-success">Active</span></td>
                    <td>20.11.2019</td>
                    <td className="text-end"><Link className="btn btn-sm btn-brand rounded font-sm mt-15" to="/pagebuyerdetail">View details</Link></td>
                  </tr>
                  <tr>
                    <td><a className="itemside" href="#">
                        <div className="info pl-3">
                          <h6 className="mb-0 title">Alexandra Pena</h6><small className="text-muted">Buyer ID: #439</small>
                        </div></a></td>
                    <td>9874563210</td>
                    <td>eleanor2022@example.com</td>
                    <td><span className="badge rounded-pill alert-danger">Inactive</span></td>
                    <td>08.07.2022</td>
                    <td className="text-end"><Link className="btn btn-sm btn-brand rounded font-sm mt-15" to="/pagebuyerdetail">View details</Link></td>
                  </tr>
                  <tr>
                    <td><a className="itemside" href="#">
                        <div className="info pl-3">
                          <h6 className="mb-0 title">Eleanor Pena</h6><small className="text-muted">Buyer ID: #439</small>
                        </div></a></td>
                    <td>9874563210</td>
                    <td>eleanor2022@example.com</td>
                    <td><span className="badge rounded-pill alert-danger">Inactive</span></td>
                    <td>08.07.2022</td>
                    <td className="text-end"><Link className="btn btn-sm btn-brand rounded font-sm mt-15" to="/pagebuyerdetail">View details</Link></td>
                  </tr>
                  <tr>
                    <td><a className="itemside" href="#">
                        <div className="info pl-3">
                          <h6 className="mb-0 title">Alex Pushkina</h6><small className="text-muted">Buyer ID: #439</small>
                        </div></a></td>
                    <td>9874563210</td>
                    <td>alex@gmail.org</td>
                    <td><span className="badge rounded-pill alert-success">Active</span></td>
                    <td>08.07.2022</td>
                    <td className="text-end"><Link className="btn btn-sm btn-brand rounded font-sm mt-15" to="/pagebuyerdetail">View details</Link></td>
                  </tr>
                  <tr>
                    <td><a className="itemside" href="#">
                        <div className="info pl-3">
                          <h6 className="mb-0 title">Eleanor Pena</h6><small className="text-muted">Buyer ID: #439</small>
                        </div></a></td>
                    <td>9874563210</td>
                    <td>eleanor2022@example.com</td>
                    <td><span className="badge rounded-pill alert-success">Active</span></td>
                    <td>08.07.2022</td>
                    <td className="text-end"><Link className="btn btn-sm btn-brand rounded font-sm mt-15" to="/pagebuyerdetail">View details</Link></td>
                  </tr>
                  <tr>
                    <td><a className="itemside" href="#">
                        <div className="info pl-3">
                          <h6 className="mb-0 title">Eleanor Pena</h6><small className="text-muted">Buyer ID: #439</small>
                        </div></a></td>
                    <td>9874563210</td>
                    <td>eleanor2022@example.com</td>
                    <td><span className="badge rounded-pill alert-success">Active</span></td>
                    <td>08.07.2022</td>
                    <td className="text-end"><Link className="btn btn-sm btn-brand rounded font-sm mt-15" to="/pagebuyerdetail">View details</Link></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="pagination-area mt-15 mb-50">
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-start">
              <li className="page-item active"><a className="page-link" href="#">01</a></li>
              <li className="page-item"><a className="page-link" href="#">02</a></li>
              <li className="page-item"><a className="page-link" href="#">03</a></li>
              <li className="page-item"><a className="page-link dot" href="#">...</a></li>
              <li className="page-item"><a className="page-link" href="#">16</a></li>
              <li className="page-item"><a className="page-link" href="#"><i className="material-icons md-chevron_right"></i></a></li>
            </ul>
          </nav>
        </div>
      </section>

        <Footer/>
        </main>

        </>
    );
}
export default PageBuyerslist;
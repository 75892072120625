import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import item1 from "../../assets/imgs/items/1.jpg";
import item2 from "../../assets/imgs/items/2.jpg";
import item3 from "../../assets/imgs/items/3.jpg";
import item4 from "../../assets/imgs/items/4.jpg";
import brand2 from "../../assets/imgs/card-brands/2.png";
import patternImg from "../../assets/imgs/image .png"

function SamplingDetailPage() {
  const productsList = [
    {
      product_name: "Pena Printed Sample",
      ref_id: "Sref-1234",
      img: "https://via.placeholder.com/150/0000FF/FFFFFF?text=T-shirt",
      type: "T-shirt",
      status: "Yet to Start",
      createdate: "08.07.2020",
      buyer: "Elsa Pena",
      manufacturer: "Mena Fabrics",
      seller_id: "#321",
      buyer_id: "#432",
      order_id: "#1001",
    },
  ];
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Sampling detail</h2>
            </div>
          </div>
          <div className="card">
            <header className="card-header">
              <Row className="align-items-center">
                <Col lg={6} md={6} className="mb-lg-0 mb-15">
                  <span>
                    <i className="material-icons md-calendar_today"></i>
                    <b>Wed, Aug 13, 2022, 4:34PM</b>
                  </span>
                  <br />
                  <small className="text-muted">Order ID: 3453012</small>
                </Col>
                <Col lg={6} md={6} className="ms-auto text-md-end">
                  <a className="btn btn-primary mx-1" href="#">
                    Generate Order
                  </a>
                  {/* <select className="form-select d-inline-block mb-lg-0 mb-15 mw-200  mx-1">
                    <option>Change status</option>
                    <option>Mark as paid</option>
                    <option>Confirmed</option>
                    <option>Draft</option>
                    <option>Invoiced</option>
                  </select> */}
                  {/* <a className="btn btn-primary  mx-1" href="#">
                    Save
                  </a> */}
                  <a className="btn btn-secondary print ms-2" href="#">
                    <i className="icon material-icons md-print"></i>
                  </a>
                </Col>
              </Row>
            </header>
            <div className="card-body">
              <Row className="mb-50 mt-20 order-info-wrap">
                <Col md={4}>
                  <article className="icontext align-items-start">
                    <span className="icon icon-sm rounded-circle bg-primary-light">
                      <i className="text-primary material-icons md-person"></i>
                    </span>
                    <div className="text">
                      <h6 className="mb-1">Buyer</h6>
                      <p className="mb-1">
                        John Alexander
                        <br /> alex@example.com
                        <br /> +998 99 22123456
                      </p>
                      <a href="#">View profile</a>
                    </div>
                  </article>
                </Col>
                <Col md={4}>
                  <article className="icontext align-items-start">
                    <span className="icon icon-sm rounded-circle bg-primary-light">
                      <i className="text-primary material-icons md-local_shipping"></i>
                    </span>
                    <div className="text">
                      <h6 className="mb-1">Manufacturer</h6>
                      <p className="mb-1">
                        Fargo express
                        <br /> alex@example.com
                        <br /> +998 99 22123456
                      </p>
                      <a href="#">View profile</a>
                    </div>
                  </article>
                </Col>
                <Col md={4}>
                  <article className="icontext align-items-start">
                    <span className="icon icon-sm rounded-circle bg-primary-light">
                      <i className="text-primary material-icons md-place"></i>
                    </span>
                    <div className="text">
                      <h6 className="mb-1">Deliver to</h6>
                      <p className="mb-1">
                        City: Tashkent, Uzbekistan
                        <br />
                        Block A, House 123, Floor 2<br /> Po Box 10000
                      </p>
                      <a href="#">View profile</a>
                    </div>
                  </article>
                </Col>
              </Row>
              <Row>
                <Col lg={7}>
                  <div className="card-body">
                    <Row>
                      <Col lg={6}><h3>Sample Name/Ref Num</h3></Col>
                      <Col lg={6}><h3 style={{fontWeight:"100"}}>: #123231</h3></Col>
                      <Col lg={6}><h3>Fabric</h3> </Col>
                      <Col lg={6}><h3 style={{fontWeight:"100"}}>: Cotton</h3></Col>
                      <Col lg={6}><h3>Styling</h3> </Col>
                      <Col lg={6}><h3 style={{fontWeight:"100"}}>: V Neck</h3></Col>
                      <Col lg={6}><h3>Trends</h3> </Col>
                      <Col lg={6}><h3 style={{fontWeight:"100"}}>: Oversized</h3></Col>
                      <Col lg={6}><h3>Rack</h3> </Col>
                      <Col lg={6}><h3 style={{fontWeight:"100"}}>: #123</h3></Col>
                    </Row>
                    {/* <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Sample Name/Ref Num</th>
                            <th>Buyer</th>
                            <th>Manufacturer</th>
                            <th>Order ID</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Created Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productsList.map((product) => (
                            <tr key={product.ref_id}>
                              <td>
                                <a className="itemside" href="#">
                                  <div className="left">
                                    <img
                                      className="img-sm img-thumbnail"
                                      src={product.img}
                                      alt={product.product_name}
                                    />
                                  </div>
                                  <div className="info">
                                    <h6 className="mb-0">
                                      {product.product_name}
                                    </h6>
                                    <small className="text-muted">
                                      Ref ID: {product.ref_id}
                                    </small>
                                  </div>
                                </a>
                              </td>
                              <td>
                                <a className="itemside" href="#">
                                  <div className="info pl-3">
                                    <h6 className="mb-0 title">
                                      {product.buyer}
                                    </h6>
                                    <small className="text-muted">
                                      Buyer ID: {product.buyer_id}
                                    </small>
                                  </div>
                                </a>
                              </td>
                              <td>
                                <a className="itemside" href="#">
                                  <div className="info pl-3">
                                    <h6 className="mb-0 title">
                                      {product.manufacturer}
                                    </h6>
                                    <small className="text-muted">
                                      Manufacturer ID: {product.seller_id}
                                    </small>
                                  </div>
                                </a>
                              </td>
                              <td>{product.order_id}</td>
                              <td>{product.type}</td>
                              <td>
                                <span
                                  className={
                                    product.status == "Delivered"
                                      ? "badge rounded-pill alert-success"
                                      : product.status == "Shipped"
                                      ? "badge rounded-pill alert-primary"
                                      : "badge rounded-pill alert-warning"
                                  }
                                >
                                  {product.status}
                                </span>
                              </td>
                              <td>{product.createdate}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div> */}
                  </div>
                  {/* <a className="btn btn-primary" href="page-orders-tracking.html">View Order Tracking</a> */}
                </Col>
                <Col lg={5}>
                  <img src={patternImg}/>
                </Col>
              </Row>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default SamplingDetailPage;

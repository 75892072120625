import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import avatar4 from "../../assets/imgs/people/avatar4.jpg";
import avatar3 from "../../assets/imgs/people/avatar3.jpg";
import avatar2 from "../../assets/imgs/people/avatar2.jpg";
// import "../../assets/css/home.css"
// import ReactFlow, { MiniMap, Controls, Background } from 'react-flow-renderer';
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  addEdge,
  Handle,
  Position,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
function Home() {
  const CustomNode = ({ data }) => {
    return (
      <div className={data.bg!="red"?"px-4 py-2 shadow-md rounded-md bg-white border-2 border-stone-400 animate_card_success":"px-4 py-2 shadow-md rounded-md bg-white border-2 border-stone-400 animate_card"}>
        <div className="flex">
          <div
            className={
              "rounded-full w-12 h-12 flex justify-center items-center bg-" +
              data.bg +
              "-100"
            }
          >
            <i className={"material-icons md-" + data.icon}></i>
          </div>
          <div className="ml-2">
            <div className={"text-" + data.bg + "-500 text-lg font-bold"}>
              {data.label}
            </div>
            <div className={"text-" + data.bg + "-500"}>{data.count}</div>
          </div>
        </div>
        {data.id == 1 ? (
          <>
            <Handle
              type="source"
              position={Position.Right}
              className="w-1 !bg-teal-500"
            />
            <Handle
              type="target"
              position={Position.Left}
              className="w-1 !bg-teal-500"
            />
          </>
        ) : (
          <>
            <Handle
              type="target"
              position={Position.Right}
              className="w-1 !bg-teal-500"
            />
            <Handle
              type="source"
              position={Position.Left}
              className="w-1 !bg-teal-500"
            />
          </>
        )}
      </div>
    );
  };
  const initialNodes = [
    {
      id: "horizontal-1",
      sourcePosition: "right",
      type: "custom",
      data: {
        label: "Buyer",
        count: "100",
        id: "1",
        icon: "store",
        bg: "gray",
      },
      position: { x: 0, y: 0 },
    },
    {
      id: "horizontal-2",
      sourcePosition: "right",
      targetPosition: "left",
      type: "custom",
      data: {
        label: "Enquiry",
        count: "2000",
        id: "1",
        icon: "support_agent",
        bg: "gray",
      },
      position: { x: 250, y: 0 },
    },
    {
      id: "horizontal-3",
      sourcePosition: "right",
      targetPosition: "left",
      type: "custom",
      data: {
        label: "Sampling",
        count: "500",
        id: "1",
        icon: "shopping_bag",
        bg: "gray",
      },
      position: { x: 520, y: 0 },
    },
    {
      id: "horizontal-4",
      sourcePosition: "right",
      targetPosition: "left",
      type: "custom",
      data: {
        label: "Development",
        count: "400",
        id: "1",
        icon: "build",
        bg: "red",
      },
      position: { x: 820, y: 0 },
    },
    {
      id: "horizontal-5",
      sourcePosition: "right",
      targetPosition: "left",
      type: "custom",
      data: {
        label: "Order Confirmation",
        count: "200",
        id: "2",
        icon: "done_all",
        bg: "green",
      },
      position: { x: 820, y: 150 },
    },
    {
      id: "horizontal-6",
      sourcePosition: "right",
      type: "custom",
      targetPosition: "left",
      data: {
        label: "Order Management",
        count: "200",
        id: "2",
        icon: "receipt_long",
        bg: "gray",
      },
      position: { x: 490, y: 150 },
    },
    {
      id: "horizontal-7",
      sourcePosition: "left",
      type: "custom",
      targetPosition: "right",
      data: {
        label: "Invoice",
        count: "200",
        id: "2",
        icon: "receipt",
        bg: "gray",
      },
      position: { x: 250, y: 150 },
    },
    {
      id: "horizontal-8",
      sourcePosition: "left",
      type: "custom",
      targetPosition: "right",
      data: {
        label: "Shipment",
        count: "250",
        id: "2",
        icon: "local_shipping",
        bg: "red",
      },
      position: { x: 0, y: 150 },
    },
    {
      id: "horizontal-9",
      sourcePosition: "left",
      type: "custom",
      data: {
        label: "Delivered",
        count: "50",
        id: "1",
        icon: "done",
        bg: "red",
      },
      position: { x: 0, y: 300 },
    },
  ];

  const initialEdges = [
    {
      id: "horizontal-e1-2",
      source: "horizontal-1",
      type: "smoothstep",
      target: "horizontal-2",
      animated: true,
    },
    {
      id: "horizontal-e2-3",
      source: "horizontal-2",
      type: "smoothstep",
      target: "horizontal-3",
      animated: true,
    },
    {
      id: "horizontal-e3-4",
      source: "horizontal-3",
      type: "smoothstep",
      target: "horizontal-4",
      animated: true,
    },
    {
      id: "horizontal-e4-5",
      source: "horizontal-4",
      type: "smoothstep",
      target: "horizontal-5",
      animated: true,
    },
    {
      id: "horizontal-e5-6",
      source: "horizontal-5",
      type: "smoothstep",
      target: "horizontal-6",
      animated: true,
    },
    {
      id: "horizontal-e6-7",
      source: "horizontal-6",
      type: "smoothstep",
      target: "horizontal-7",
      animated: true,
    },
    {
      id: "horizontal-e7-8",
      source: "horizontal-7",
      type: "smoothstep",
      target: "horizontal-8",
      animated: true,
    },
    {
      id: "horizontal-e8-9",
      source: "horizontal-8",
      type: "smoothstep",
      target: "horizontal-9",
      animated: true,
    },
  ];
  const data2 = [
    {
      orderNo: "#ORD001",
      manufacturer: "Manufacturer A",
      buyer: "Buyer A",
      invoiceAmount: "₹1,000.00",
      amountOutstanding: "₹200.00",
      status: "Pending",
    },
    {
      orderNo: "#ORD002",
      manufacturer: "Manufacturer B",
      buyer: "Buyer B",
      invoiceAmount: "₹1,500.00",
      amountOutstanding: "₹1,000.00",
      status: "Completed",
    },
    {
      orderNo: "#ORD003",
      manufacturer: "Manufacturer C",
      buyer: "Buyer C",
      invoiceAmount: "₹750.00",
      amountOutstanding: "₹0.00",
      status: "In Progress",
    },
    {
      orderNo: "#ORD004",
      manufacturer: "Manufacturer D",
      buyer: "Buyer D",
      invoiceAmount: "₹2,000.00",
      amountOutstanding: "₹500.00",
      status: "OverDue",
    },
    {
      orderNo: "#ORD005",
      manufacturer: "Manufacturer E",
      buyer: "Buyer E",
      invoiceAmount: "₹1,200.00",
      amountOutstanding: "₹1,200.00",
      status: "Pending",
    },
  ];

  const data1 = [
    {
      orderNo: "#ORD001",
      manufacturer: "Manufacturer A",
      status: "Delayed",
      dueDate: "15 Oct, 2022",
    },
    {
      orderNo: "#ORD002",
      manufacturer: "Manufacturer B",
      status: "Completed",
      dueDate: "20 Oct, 2022",
    },
    {
      orderNo: "#ORD003",
      manufacturer: "Manufacturer C",
      status: "In Progress",
      dueDate: "18 Oct, 2022",
    },
    {
      orderNo: "#ORD004",
      manufacturer: "Manufacturer D",
      status: "Cancelled",
      dueDate: "25 Oct, 2022",
    },
    {
      orderNo: "#ORD005",
      manufacturer: "Manufacturer E",
      status: "Delayed",
      dueDate: "30 Oct, 2022",
    },
  ];

  const data = [
    {
      orderId: "#SK2540",
      shipmentId: "12321312",
      status: "Delivered",
      expected: "07 Oct, 2022",
    },
    {
      orderId: "#SK2541",
      shipmentId: "12321313",
      status: "Delayed",
      expected: "08 Oct, 2022",
    },
    {
      orderId: "#SK2542",
      shipmentId: "12321314",
      status: "Shipped",
      expected: "09 Oct, 2022",
    },
    {
      orderId: "#SK2543",
      shipmentId: "12321315",
      status: "Delivered",
      expected: "10 Oct, 2022",
    },
    {
      orderId: "#SK2544",
      shipmentId: "12321316",
      status: "Cancelled",
      expected: "11 Oct, 2022",
    },
  ];

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <div className="content-header mb-1 ">
            <div>
              <h3 className="content-title card-title">
                Dashboard - AI Powered Current Month Progress
              </h3>
              {/* <p>Whole data about your business here</p> */}
            </div>
            {/* <div>
              <a className="btn btn-primary" href="#">
                <i className="text-muted material-icons md-post_add"></i>Create
                report
              </a>
            </div> */}
          </div>
          <Row>
            <h3 className="content-title card-title"></h3>
            <div style={{ height: 400 }}>
              <ReactFlow
                nodes={initialNodes}
                edges={initialEdges}
                zoomOnScroll={false}
                nodeTypes={{
                  custom: CustomNode,
                }}
                fitView
                attributionPosition="bottom-left"
              ></ReactFlow>
            </div>
          </Row>

          <Row>
            <h3 className="content-title card-title">Time & Action Calendar - T&A</h3>
            <Col xl={6} lg={12}>
              <div className="card mb-4">
                <article className="card-body">
                  <h5 className="card-title">Shipment Reminders</h5>
                  <div className="table-responsive">
                    <table className="table align-middle table-nowrap mb-0">
                      <thead className="table-light">
                        <tr>
                          <th className="align-middle" scope="col">
                            Order ID
                          </th>
                          <th className="align-middle" scope="col">
                            Shipment ID
                          </th>
                          <th className="align-middle" scope="col">
                            Status
                          </th>
                          <th className="align-middle" scope="col">
                            Expected
                          </th>
                          <th className="align-middle" scope="col">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item) => (
                          <tr key={item.orderId}>
                            <td>
                              <a className="fw-bold" href="#">
                                {item.orderId}
                              </a>
                            </td>
                            <td>{item.shipmentId}</td>
                            <td>
                              <span
                                className={`badge rounded-pill alert-${
                                  item.status === "Delivered"
                                    ? "success"
                                    : item.status === "In Transit"
                                    ? "warning"
                                    : item.status === "Shipped"
                                    ? "info"
                                    : "danger"
                                }`}
                              >
                                {item.status}
                              </span>
                            </td>
                            <td>{item.expected}</td>
                            <td>
                              <a className="btn btn-xs" href="#">
                                Track
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </article>
              </div>
            </Col>
            <Col xl={6} lg={12}>
              <div className="card mb-4">
                <article className="card-body">
                  <h5 className="card-title">Orders Reminder</h5>
                  <div className="table-responsive">
                    <table className="table align-middle table-nowrap mb-0">
                      <thead className="table-light">
                        <tr>
                          <th className="align-middle" scope="col">
                            Order No
                          </th>
                          <th className="align-middle" scope="col">
                            Manufacturer
                          </th>
                          <th className="align-middle" scope="col">
                            Status
                          </th>
                          <th className="align-middle" scope="col">
                            Due Date
                          </th>
                          <th className="align-middle" scope="col">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data1.map((item) => (
                          <tr key={item.orderNo}>
                            <td>
                              <a className="fw-bold" href="#">
                                {item.orderNo}
                              </a>
                            </td>
                            <td>{item.manufacturer}</td>
                            <td>
                              <span
                                className={`badge rounded-pill alert-${
                                  item.status === "Completed"
                                    ? "success"
                                    : item.status === "Delayed"
                                    ? "warning"
                                    : item.status === "In Progress"
                                    ? "info"
                                    : "danger"
                                }`}
                              >
                                {item.status}
                              </span>
                            </td>
                            <td>{item.dueDate}</td>
                            <td>
                              <a className="btn btn-xs" href="#">
                                View
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </article>
              </div>
            </Col>
            <Col xl={12} lg={12}>
              <div className="card mb-4">
                <article className="card-body">
                  <h5 className="card-title">Payments Reminder</h5>
                  <div className="table-responsive">
                    <table className="table align-middle table-nowrap mb-0">
                      <thead className="table-light">
                        <tr>
                          <th className="align-middle" scope="col">
                            Order No
                          </th>
                          <th className="align-middle" scope="col">
                            Manufacturer
                          </th>
                          <th className="align-middle" scope="col">
                            Buyer
                          </th>
                          <th className="align-middle" scope="col">
                            Invoice Amount
                          </th>
                          <th className="align-middle" scope="col">
                            Amount Outstanding
                          </th>
                          <th className="align-middle" scope="col">
                            Status
                          </th>
                          <th className="align-middle" scope="col">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data2.map((item) => (
                          <tr key={item.orderNo}>
                            <td>
                              <a className="fw-bold" href="#">
                                {item.orderNo}
                              </a>
                            </td>
                            <td>{item.manufacturer}</td>
                            <td>{item.buyer}</td>
                            <td>{item.invoiceAmount}</td>
                            <td>{item.amountOutstanding}</td>
                            <td>
                              <span
                                className={`badge rounded-pill alert-${
                                  item.status === "Completed"
                                    ? "success"
                                    : item.status === "Pending"
                                    ? "warning"
                                    : item.status === "In Progress"
                                    ? "info"
                                    : "danger"
                                }`}
                              >
                                {item.status}
                              </span>
                            </td>
                            <td>
                              <a className="btn btn-xs" href="#">
                                View
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </article>
              </div>
            </Col>
          </Row>
          <Row>
            <h3 className="content-title card-title">Stats</h3>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-primary-light">
                    <i className="text-primary material-icons md-shopping_bag"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Samples</h6>
                    <span>8300</span>
                    <span className="text-sm">
                      in 19 categories and 10 brands
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-success-light">
                    <i className="text-success material-icons md-local_shipping"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Orders Total</h6>
                    <span>130000</span>
                    <span className="text-sm">
                      Increase by 15% from Last year
                    </span>
                    {/* <span className="text-sm">
                      Shipping fees are not included
                    </span> */}
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-warning-light">
                    {/* <i className="text-success material-icons md-local_shipping"></i> */}
                    <i className="text-warning material-icons md-monetization_on"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Revenue Total</h6>
                    <span>₹1,00,456.5</span>
                    {/* <span className="text-sm">Excluding orders in transit</span> */}
                    <span className="text-sm">
                      Increase by 15% from Last year
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-info-light">
                    <i className="text-info material-icons md-qr_code"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Profit %</h6>
                    <span>100%</span>
                    <span className="text-sm">
                      Increase by 15% from Last year
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-primary-light">
                    <i className="text-primary material-icons md-shopping_bag"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Buyers</h6>
                    <span>300</span>
                    <span className="text-sm">in 4 locations</span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-success-light">
                    <i className="text-success material-icons md-local_shipping"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">
                      Orders Received This Month
                    </h6>
                    <span>1300</span>
                    <span className="text-sm">
                      Increase by 15% from Last month
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-warning-light">
                    <i className="text-warning material-icons md-monetization_on"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">
                      Revenue Received This Month
                    </h6>
                    <span>₹13,456.5</span>
                    <span className="text-sm">
                      Increase by 15% from Last month
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-info-light">
                    <i className="text-info material-icons md-qr_code"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Profit % this Month</h6>
                    <span>60%</span>
                    <span className="text-sm">
                      Increase by 15% from Last month
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-primary-light">
                    <i className="text-primary material-icons md-shopping_bag"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Manufacturers</h6>
                    <span>1300</span>
                    <span className="text-sm">in 10 locations</span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-success-light">
                    <i className="text-success material-icons md-local_shipping"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Orders Received Today</h6>
                    <span>10</span>
                    <span className="text-sm">
                      Increase by 15% from yesterday
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-warning-light">
                    <i className="text-warning material-icons md-monetization_on"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Revenue Received Today</h6>
                    <span>₹1,300.5</span>
                    <span className="text-sm">
                      Increase by 15% from yesterday
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-info-light">
                    <i className="text-info material-icons md-qr_code"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Profit % Today</h6>
                    <span>90%</span>
                    <span className="text-sm">
                      Increase by 15% from yesterday
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-primary-light">
                    <i className="text-primary material-icons md-shopping_bag"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Purchase Orders</h6>
                    <span>100</span>
                    <span className="text-sm"></span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-success-light">
                    <i className="text-success material-icons md-local_shipping"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">
                      Orders Forecast This month
                    </h6>
                    <span>2300</span>
                    <span className="text-sm">
                      Increase by 15% from last month
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-warning-light">
                    <i className="text-warning material-icons md-monetization_on"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">
                      Revenue Forecast this month
                    </h6>
                    <span>₹23,456.5</span>
                    <span className="text-sm">
                      Increase by 15% from last month
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-info-light">
                    <i className="text-info material-icons md-qr_code"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">
                      Profit % Forecast this month
                    </h6>
                    <span>50%</span>
                    <span className="text-sm">
                      Increase by 15% from last month
                    </span>
                  </div>
                </article>
              </div>
            </Col>
          </Row>
          <Row>
              <h4 className="card-title">Shipment Stats</h4>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-primary-light">
                    <i className="text-primary material-icons md-corporate_fare"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">
                      Manufacturing Inprogress
                    </h6>
                    <span>100</span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-success-light">
                    <i className="text-success material-icons md-receipt"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Dispatched Orders</h6>
                    <span>2300</span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-warning-light">
                    <i className="text-warning material-icons md-local_shipping"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">In transit</h6>
                    <span>2300</span>
                  </div>
                </article>
              </div>
            </Col>

            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-success-light">
                    <i className="text-success material-icons md-done_all"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Delivered Orders</h6>
                    <span>100</span>
                  </div>
                </article>
              </div>
            </Col>
          </Row>
          <div className="card mb-4">
            <header className="card-header">
              <h4 className="card-title">Latest orders</h4>
              <div className="row align-items-center">
                <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                  <div className="custom_select">
                    <select className="form-select select-nice">
                      <option selected="">All Categories</option>
                      <option>Women&apos;s Clothing</option>
                      <option>Men&apos;s Clothing</option>
                      <option>Cellphones</option>
                      <option>Computer &amp; Office</option>
                      <option>Consumer Electronics</option>
                      <option>Jewelry &amp; Accessories</option>
                      <option>Home &amp; Garden</option>
                      <option>Luggage &amp; Bags</option>
                      <option>Shoes</option>
                      <option>Mother &amp; Kids</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-2 col-6">
                  <input
                    className="form-control"
                    type="date"
                    value="02.05.2022"
                  />
                </div>
                <div className="col-md-2 col-6">
                  <div className="custom_select">
                    <select className="form-select select-nice">
                      <option selected="">Status</option>
                      <option>All</option>
                      <option>Paid</option>
                      <option>Chargeback</option>
                      <option>Refund</option>
                    </select>
                  </div>
                </div>
              </div>
            </header>
            <div className="card-body">
              <div className="table-responsive">
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap mb-0">
                    <thead className="table-light">
                      <tr>
                        <th className="text-center" scope="col">
                          <div className="form-check align-middle">
                            <input
                              className="form-check-input"
                              id="transactionCheck01"
                              type="checkbox"
                            />
                            <label
                              className="form-check-label"
                              for="transactionCheck01"
                            ></label>
                          </div>
                        </th>
                        <th className="align-middle" scope="col">
                          Order ID
                        </th>
                        <th className="align-middle" scope="col">
                          Billing Name
                        </th>
                        <th className="align-middle" scope="col">
                          Date
                        </th>
                        <th className="align-middle" scope="col">
                          Total
                        </th>
                        <th className="align-middle" scope="col">
                          View Details
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              id="transactionCheck02"
                              type="checkbox"
                            />
                            <label
                              className="form-check-label"
                              for="transactionCheck02"
                            ></label>
                          </div>
                        </td>
                        <td>
                          <a className="fw-bold" href="#">
                            #SK2540
                          </a>
                        </td>
                        <td>Neal Matthews</td>
                        <td>07 Oct, 2022</td>
                        <td>₹400</td>
                        <td>
                          <a className="btn btn-xs" href="#">
                            {" "}
                            View details
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              id="transactionCheck03"
                              type="checkbox"
                            />
                            <label
                              className="form-check-label"
                              for="transactionCheck03"
                            ></label>
                          </div>
                        </td>
                        <td>
                          <a className="fw-bold" href="#">
                            #SK2541
                          </a>
                        </td>
                        <td>Jamal Burnett</td>
                        <td>07 Oct, 2022</td>
                        <td>₹380</td>
                        <td>
                          <a className="btn btn-xs" href="#">
                            {" "}
                            View details
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              id="transactionCheck04"
                              type="checkbox"
                            />
                            <label
                              className="form-check-label"
                              for="transactionCheck04"
                            ></label>
                          </div>
                        </td>
                        <td>
                          <a className="fw-bold" href="#">
                            #SK2542
                          </a>
                        </td>
                        <td>Juan Mitchell</td>
                        <td>06 Oct, 2022</td>
                        <td>₹384</td>
                        <td>
                          <a className="btn btn-xs" href="#">
                            {" "}
                            View details
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              id="transactionCheck05"
                              type="checkbox"
                            />
                            <label
                              className="form-check-label"
                              for="transactionCheck05"
                            ></label>
                          </div>
                        </td>
                        <td>
                          <a className="fw-bold" href="#">
                            #SK2543
                          </a>
                        </td>
                        <td>Barry Dick</td>
                        <td>05 Oct, 2022</td>
                        <td>₹412</td>
                        <td>
                          <a className="btn btn-xs" href="#">
                            {" "}
                            View details
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              id="transactionCheck06"
                              type="checkbox"
                            />
                            <label
                              className="form-check-label"
                              for="transactionCheck06"
                            ></label>
                          </div>
                        </td>
                        <td>
                          <a className="fw-bold" href="#">
                            #SK2544
                          </a>
                        </td>
                        <td>Ronald Taylor</td>
                        <td>04 Oct, 2022</td>
                        <td>₹404</td>
                        <td>
                          <a className="btn btn-xs" href="#">
                            {" "}
                            View details
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              id="transactionCheck07"
                              type="checkbox"
                            />
                            <label
                              className="form-check-label"
                              for="transactionCheck07"
                            ></label>
                          </div>
                        </td>
                        <td>
                          <a className="fw-bold" href="#">
                            #SK2545
                          </a>
                        </td>
                        <td>Jacob Hunter</td>
                        <td>04 Oct, 2022</td>
                        <td>₹392</td>
                        <td>
                          <a className="btn btn-xs" href="#">
                            {" "}
                            View details
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="pagination-area mt-30 mb-50">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-start">
                <li className="page-item active">
                  <a className="page-link" href="#">
                    01
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    02
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    03
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link dot" href="#">
                    ...
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    16
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    <i className="material-icons md-chevron_right"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default Home;

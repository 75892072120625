import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import uploads from "../../assets/imgs/theme/upload.svg";
import { Link } from "react-router-dom";

function Pageformproduct() {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <Row>
            <Col md={12}>
              <div className="content-header">
                <h2 className="content-title">Create New Sample Order</h2>
                <div>
                  {/* <button className="btn btn-light rounded font-sm mr-5 text-body hover-up">Save to draft</button> */}
                  <Link to="/pageproductslist" className="btn btn-md rounded font-sm hover-up">
                    Create Sample Order
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={9}>
              <div className="card mb-4">
                <div className="card-header">
                  <h4>Basic</h4>
                </div>
                <div className="card-body">
                  <form>
                    {/* <div className="mb-4">
                    <label className="form-label" for="product_name">Sample title</label>
                    <input className="form-control" id="product_name" type="text" placeholder="Type here"/>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Full description</label>
                    <textarea className="form-control" placeholder="Type here" rows="4"></textarea>
                  </div> */}
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label">Sample Order Ref No</label>
                          <div className="row gx-2"></div>
                          <input
                            className="form-control"
                            placeholder="Type Here"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label">Buyer</label>
                          <select className="form-select">
                            <option> Self</option>
                            <option> Elsa</option>
                            <option> Rodrick</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <label className="form-label">Manufacturer</label>
                        <select className="form-select">
                          <option> Fabrics & co.</option>
                          <option> Mena Clothings</option>
                          <option> Rebel Materials</option>
                        </select>
                      </div>
                      <div className="col-lg-4">
                        <label className="form-label">Material</label>
                        <select className="form-select">
                          <option> Satin</option>
                          <option> Cotton</option>
                          <option> Linen</option>
                        </select>
                      </div>
                      <div className="col-lg-4">
                        <label className="form-label">Type</label>
                        <select className="form-select">
                          <option> Tshirt</option>
                          <option> Shirt</option>
                          <option> Pants</option>
                        </select>
                      </div>
                      <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label">Color</label>
                          <select className="form-select">
                            <option> Red</option>
                            <option> Green</option>
                            <option> Mixed Colors</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label">Order Deadline</label>
                          <input
                            className="form-control"
                            id="product_name"
                            type="date"
                            placeholder="Date"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label">Manufacturer Delivery Date</label>
                          <input
                            className="form-control"
                            id="product_name"
                            type="date"
                            placeholder="Date"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label">
                            Expected Order Quantity
                          </label>
                          <input
                            className="form-control"
                            id="product_name"
                            type="number"
                            placeholder="Type Here"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card mb-4">
                <div className="card-header">
                  <h4>Upload Sample Designs</h4>
                </div>
                <div className="card-body">
                  <div className="input-upload">
                    <img src={uploads} alt="" />
                    <input className="form-control" type="file" />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default Pageformproduct;

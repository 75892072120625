import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import item1 from "../../assets/imgs/items/1.jpg";
import item2 from "../../assets/imgs/items/2.jpg";
import item3 from "../../assets/imgs/items/3.jpg";
import item4 from "../../assets/imgs/items/4.jpg";
import brand2 from "../../assets/imgs/card-brands/2.png";

function Pageordersdetail(){
    return(
        <>

        <Sidebar/>
        <main className="main-wrap">
        <Header/>
            <section className="content-main">
                <div className="content-header">
                <div>
                    <h2 className="content-title card-title">Purchase Order detail</h2>
                    <p>Details for Order ID: 3453012</p>
                </div>
                </div>
                <div className="card">
                <header className="card-header">
                    <Row className="align-items-center">
                    <Col lg={6} md={6} className="mb-lg-0 mb-15"><span><i className="material-icons md-calendar_today"></i><b>Wed, Aug 13, 2022, 4:34PM</b></span><br/><small className="text-muted">Order ID: 3453012</small></Col>
                    <Col lg={6} md={6} className="ms-auto text-md-end">
                    <a className="btn btn-primary mx-1" href="#">Generate Invoice</a>
                        <select className="form-select d-inline-block mb-lg-0 mb-15 mw-200  mx-1">
                        <option>Change status</option>
                        <option>Mark as paid</option>
                        <option>Confirmed</option>
                        <option>Draft</option>
                        <option>Invoiced</option>
                        </select><a className="btn btn-primary  mx-1" href="#">Save</a><a className="btn btn-secondary print ms-2" href="#"><i className="icon material-icons md-print"></i></a>
                    </Col>
                    </Row>
                </header>
                <div className="card-body">
                    <Row className="mb-50 mt-20 order-info-wrap">
                    <Col md={4}>
                        <article className="icontext align-items-start"><span className="icon icon-sm rounded-circle bg-primary-light"><i className="text-primary material-icons md-person"></i></span>
                        <div className="text">
                            <h6 className="mb-1">Buyer</h6>
                            <p className="mb-1">John Alexander<br/> alex@example.com<br/> +998 99 22123456</p><a href="#">View profile</a>
                        </div>
                        </article>
                    </Col>
                    <Col md={4}>
                        <article className="icontext align-items-start"><span className="icon icon-sm rounded-circle bg-primary-light"><i className="text-primary material-icons md-local_shipping"></i></span>
                        <div className="text">
                            <h6 className="mb-1">Manufacturer</h6>
                            <p className="mb-1">Fargo express<br/> alex@example.com<br/> +998 99 22123456</p><a href="#">View profile</a>
                        </div>
                        </article>
                    </Col>
                    <Col md={4}>
                        <article className="icontext align-items-start"><span className="icon icon-sm rounded-circle bg-primary-light"><i className="text-primary material-icons md-place"></i></span>
                        <div className="text">
                            <h6 className="mb-1">Deliver to</h6>
                            <p className="mb-1">City: Tashkent, Uzbekistan<br/>Block A, House 123, Floor 2<br/> Po Box 10000</p><a href="#">View profile</a>
                        </div>
                        </article>
                    </Col>
                    </Row>
                    <Row>
                    <Col lg={7}>
                        <div className="table-responsive">
                        <table className="table">
                            <thead>
                            <tr>
                                <th width="40%">Items</th>
                                <th width="20%">Unit Price</th>
                                <th width="20%">Quantity</th>
                                <th className="text-end" width="20%">Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td><a className="itemside" href="#">
                                    <div className="info"> T-shirt blue, XXL size</div></a></td>
                                <td> ₹44.25</td>
                                <td> 2</td>
                                <td className="text-end"> ₹99.50</td>
                            </tr>
                            <tr>
                                <td><a className="itemside" href="#">
                                    <div className="info"> Winter jacket for men</div></a></td>
                                <td> ₹7.50</td>
                                <td> 2</td>
                                <td className="text-end"> ₹15.00</td>
                            </tr>
                            <tr>
                                <td><a className="itemside" href="#">
                                    <div className="info"> Jeans wear for men</div></a></td>
                                <td> ₹43.50</td>
                                <td> 2</td>
                                <td className="text-end"> ₹102.04</td>
                            </tr>
                            <tr>
                                <td><a className="itemside" href="#">
                                    <div className="info"> Product name color and size</div></a></td>
                                <td> ₹99.00</td>
                                <td> 3</td>
                                <td className="text-end"> ₹297.00</td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                <article className="float-end">
                                    <dl className="dlist">
                                    <dt>Subtotal:</dt>
                                    <dd>₹973.35</dd>
                                    </dl>
                                    <dl className="dlist">
                                    <dt>Shipping cost:</dt>
                                    <dd>₹10.00</dd>
                                    </dl>
                                    <dl className="dlist">
                                    <dt>Grand total:</dt>
                                    <dd><b className="h5">₹983.00</b></dd>
                                    </dl>
                                    <dl className="dlist">
                                    <dt className="text-muted">Status:</dt>
                                    <dd><span className="badge rounded-pill alert-success text-success">Payment done</span></dd>
                                    </dl>
                                </article>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                        {/* <a className="btn btn-primary" href="page-orders-tracking.html">View Order Tracking</a> */}
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={4}>
                        {/* <div className="box shadow-sm bg-light">
                        <h6 className="mb-15">Payment info</h6>
                        <p><img className="border" src={brand2} height="20"/> Master Card **** **** 4768<br/>                                    Business name: Grand Market LLC<br/>                                    Phone: +1 (800) 555-154-52</p>
                        </div> */}
                        <div className="h-25 pt-4">
                        <div className="mb-3">
                            <label>Notes</label>
                            <textarea className="form-control" id="notes" name="notes" placeholder="Type some note"></textarea>
                        </div>
                        <button className="btn btn-primary">Save note</button>
                        </div>
                    </Col>
                    </Row>
                </div>
                </div>
            </section>

        <Footer/>
        </main>
        </>
    );
}
export default Pageordersdetail;
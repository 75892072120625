import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import uploads from "../../assets/imgs/theme/upload.svg";

function Reportshipment() {
  const data = [
    {
        id: 452,
        po: 4522,
      buyerName: "Devon Lane",
      manufacturerName: "Manufacturer A",
      price: "₹948.55",
      status: "Delivered",
      date: "07.05.2022",
    },
    {
      id: 453,
      po: 4521,
      buyerName: "Alice Smith",
      manufacturerName: "Manufacturer B",
      price: "₹1200.00",
      status: "In Transit",
      date: "08.05.2022",
    },
    {
      id: 454,
      po: 4122,
      buyerName: "John Doe",
      manufacturerName: "Manufacturer C",
      price: "₹500.75",
      status: "Cancelled",
      date: "09.05.2022",
    },
    {
      id: 455,
      po: 212,
      buyerName: "Jane Roe",
      manufacturerName: "Manufacturer D",
      price: "₹750.50",
      status: "Delivered",
      date: "10.05.2022",
    },
    {
      id: 456,
      po: 2536,
      buyerName: "Richard Nixon",
      manufacturerName: "Manufacturer E",
      price: "₹1200.00",
      status: "Returned",
      date: "11.05.2022",
    },
    {
      id: 457,
      po: 5689,
      buyerName: "George Bush",
      manufacturerName: "Manufacturer F",
      price: "₹200.00",
      status: "In Transit",
      date: "12.05.2022",
    },
    {
      id: 458,
      po: 4563,
      buyerName: "Barack Obama",
      manufacturerName: "Manufacturer G",
      price: "₹300.00",
      status: "Delivered",
      date: "13.05.2022",
    },
    {
      id: 459,
      po: 3201,
      buyerName: "Donald Trump",
      manufacturerName: "Manufacturer H",
      price: "₹850.25",
      status: "Delivered",
      date: "14.05.2022",
    },
    {
      id: 460,
      po: 5896,
      buyerName: "Joe Biden",
      manufacturerName: "Manufacturer I",
      price: "₹950.50",
      status: "Delivered",
      date: "15.05.2022",
    },
    {
      id: 461,
      po: 623,
      buyerName: "Kamala Harris",
      manufacturerName: "Manufacturer J",
      price: "₹1200.00",
      status: "In Transit",
      date: "16.05.2022",
    },
  ];
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <Row>
            <Col md={12}>
              <div className="content-header">
                <h2 className="content-title">Shipment Report</h2>
                <div>
                  {/* <button className="btn btn-light rounded font-sm mr-5 text-body hover-up">Save to In Transit</button> */}
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="card mb-4">
                <div className="card-header">
                  <h4>Filters</h4>
                </div>
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="mb-4">
                          <label className="form-label">Buyers</label>
                          <div className="row gx-2"></div>
                          <select className="form-select">
                            <option> All</option>
                            <option> Swiss Co</option>
                            <option> Lewy Co</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="mb-4">
                          <label className="form-label">Manufacturers</label>
                          <div className="row gx-2"></div>
                          <select className="form-select">
                            <option> All</option>
                            <option> Fabrics & co</option>
                            <option> Mena Brands</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="mb-4">
                          <label className="form-label">Status</label>
                          <div className="row gx-2"></div>
                          <select className="form-select">
                            <option> All</option>
                            <option> Dispatched</option>
                            <option> Delivered</option>
                            <option> In transit</option>
                            <option> Delayed</option>
                            <option> Returned</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="mb-4">
                          <label className="form-label">Date From</label>
                          <input
                            className="form-control"
                            placeholder="₹"
                            type="date"
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="mb-4">
                          <label className="form-label">Date To</label>
                          <input
                            className="form-control"
                            placeholder="₹"
                            type="date"
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <label className="form-label">&nbsp;</label>
                        <button className="form-control btn btn-md rounded font-sm hover-up ">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="card mb-4">
                <div className="card-header">
                  <Row>
                    <Col lg={10}>
                      <h4>Shipment Report</h4>
                    </Col>
                    <Col lg={1}>
                      <button className="form-control btn btn-md rounded font-sm hover-up ">
                        Export
                      </button>
                    </Col>
                    <Col lg={1}>
                      <button className="form-control btn btn-md rounded font-sm hover-up ">
                        Email
                      </button>
                    </Col>
                  </Row>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>Shipment No</th>
                          <th>Order No</th>
                          <th>Buyer name</th>
                          <th>Invoice Date</th>
                          {/* <th>Manufacturer name</th> */}
                          <th>Price</th>
                          <th>Status</th>
                          <th>Expected Delivery Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item) => (
                          <tr key={item.id}>
                          <td>123{item.po}</td>
                            <td>{item.po}</td>
                            <td>{item.date}</td>
                            <td>
                              <b>{item.buyerName}</b>
                            </td>
                            {/* <td>{item.manufacturerName}</td> */}
                            <td>{item.price}</td>
                            <td>
                              <span
                                className={`badge rounded-pill alert-${
                                  item.status === "Delivered"
                                    ? "success"
                                    : "warning"
                                }`}
                              >
                                {item.status}
                              </span>
                            </td>
                            <td>{item.date}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="pagination-area mt-15 mb-50">
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-start">
                        <li className="page-item active">
                          <a className="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link dot" href="#">
                            ...
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            16
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            <i className="material-icons md-chevron_right"></i>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default Reportshipment;

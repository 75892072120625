import { Col, Container, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import qrImg from "../../assets/imgs/pngwing.com.png";

function Pageinvoice() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Invoice</h2>
              <p>Details for Order ID: 3453012</p>
            </div>
          </div>
          <Row>
            <Col lg={12} md={12}>
              <div className="card">
                <header className="card-header">
                  <Row className="align-items-center">
                    <Col lg={6} md={6} className="mb-lg-0 mb-15">
                      <span>
                        <i className="material-icons md-calendar_today"></i>
                        <b>Wed, Aug 13, 2022, 4:34PM</b>
                      </span>
                      <br />
                      <small className="text-muted">Order ID: 3453012</small>
                    </Col>
                    <Col lg={6} md={6} className="ms-auto text-md-end">
                      <a className="btn btn-primary" href="#">
                        <i className="icon material-icons md-cloud_download mr-5"></i>
                        Save PDF
                      </a>
                      <a className="btn btn-secondary print ms-2" href="#">
                        <i className="icon material-icons md-print mr-5"></i>
                        Print
                      </a>
                    </Col>
                  </Row>
                </header>
                <div className="card-body">
                  <div className="receipt-content">
                    <Container className="bootstrap snippets bootdey">
                      <Row>
                        <Col md={12}>
                          <div className="invoice-wrapper">
                            <Row>
                              <Col sm={6}>
                                <div className="intro">
                                  Hi<strong> Steven Jobs</strong>,<br /> This is
                                  the receipt for a payment of
                                  <strong> ₹312.00</strong> for your works
                                </div>
                              </Col>
                              <Col sm={6} className="text-end">
                                {" "}
                                <div className="d-flex justify-content-end" style={{ height: '100px' }}>
                                  <img src={qrImg} alt=""  />
                                </div>
                              </Col>
                            </Row>
                            <div className="payment-info">
                              <Row>
                                <Col sm={6}>
                                  <span>Payment No.</span>
                                  <strong>434334343</strong>
                                </Col>
                                <Col sm={6} className="text-end">
                                  <span>Payment Date</span>
                                  <strong>Jul 09, 2022 - 12:20 pm</strong>
                                </Col>
                              </Row>
                            </div>
                            <div className="payment-details">
                              <Row>
                                <Col sm={6}>
                                  <span>Buyer</span>
                                  <strong>Andres felipe posada</strong>
                                  <p>
                                    989 5th Avenue
                                    <br />
                                    City of monterrey
                                    <br />
                                    55839
                                    <br />
                                    USA
                                    <br />
                                    <a href="#">jonnydeff@gmail.com</a>
                                  </p>
                                </Col>
                                <Col sm={6} className="text-end">
                                  <span>Payment To</span>
                                  <strong>Juan fernando arias</strong>
                                  <p>
                                    344 9th Avenue
                                    <br />
                                    San Francisco
                                    <br />
                                    99383
                                    <br />
                                    USA
                                    <br />
                                    <a href="#">juanfer@gmail.com</a>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                            <div className="line-items">
                              <div className="headers clearfix">
                                <Row>
                                  <Col md={4}>Description</Col>
                                  <Col md={3}>Quantity</Col>
                                  <Col md={5} className="text-end">
                                    Amount
                                  </Col>
                                </Row>
                              </div>
                              <div className="items">
                                <Row className="item">
                                  <Col md={4} className="desc">
                                    T-shirt blue, XXL size
                                  </Col>
                                  <Col md={3} className="qty">
                                    3
                                  </Col>
                                  <Col md={5} className="amount text-end">
                                    ₹60.00
                                  </Col>
                                </Row>
                                <Row className="item">
                                  <Col md={4} className="desc">
                                    Winter jacket for men
                                  </Col>
                                  <Col md={3} className="qty">
                                    1
                                  </Col>
                                  <Col md={5} className="amount text-end">
                                    ₹20.00
                                  </Col>
                                </Row>
                                <Row className="item">
                                  <Col md={4} className="desc">
                                    Jeans wear for men
                                  </Col>
                                  <Col md={3} className="qty">
                                    2
                                  </Col>
                                  <Col md={5} className="amount text-end">
                                    ₹18.00
                                  </Col>
                                </Row>
                              </div>
                              <div className="total text-end">
                                <p className="extra-notes">
                                  <strong>Extra Notes</strong>Please send all
                                  items at the same time to shipping address by
                                  next week. Thanks a lot.
                                </p>
                                <div className="field">
                                  Subtotal<span>₹379.00</span>
                                </div>
                                <div className="field">
                                  Shipping<span>₹0.00</span>
                                </div>
                                <div className="field">
                                  Discount<span>4.5%</span>
                                </div>
                                <div className="field grand-total">
                                  Total<span>₹312.00</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="footer">
                            Copyright © {currentYear}, Apricot Services
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default Pageinvoice;

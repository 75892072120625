import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import uploads from "../../assets/imgs/theme/upload.svg";
import { Link } from "react-router-dom";

function EnquiryCreate() {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <Row className="justify-content-center">
            <Col md={12}>
              <div className="content-header">
                <h2 className="content-title">Enquiry Create Page</h2>
                <div>
                  {/* <button className="btn btn-light rounded font-sm mr-5 text-body hover-up">Save to draft</button> */}
                  <Link to="/pageproductslist" className="btn btn-md rounded font-sm hover-up">
                    Create Enquiry
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="card mb-4">
                <div className="card-header">
                  <h4>Basic</h4>
                </div>
                <div className="card-body">
                  <form>
                    {/* <div className="mb-4">
                    <label className="form-label" for="product_name">Sample title</label>
                    <input className="form-control" id="product_name" type="text" placeholder="Type here"/>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Full description</label>
                    <textarea className="form-control" placeholder="Type here" rows="4"></textarea>
                  </div> */}
                    <div className="row">
                      
                    <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label">Buyer</label>
                          <select className="form-select">
                            <option> Self</option>
                            <option> Elsa</option>
                            <option> Rodrick</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label">Sample Ref No</label>
                          <div className="row gx-2"></div>
                          <select className="form-select">
                            <option> Self</option>
                            <option> Elsa</option>
                            <option> Rodrick</option>
                          </select>
                        </div>
                      </div>
                   
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label">Description</label>
                          <input
                            className="form-control"
                            id="product_name"
                            type="text"
                            placeholder="Description"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label">
                            Remarks
                          </label>
                          <input
                            className="form-control"
                            id="product_name"
                            type="text"
                            placeholder="Type Here"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Col>
          
          </Row>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default EnquiryCreate;

import { Link } from "react-router-dom";
import logo from "../../src/assets/imgs/logoapricot.png";

function Sidebar() {
  return (
    <>
      <div className="screen-overlay"></div>
      <aside className="navbar-aside" id="offcanvas_aside">
        <div className="aside-top">
          <Link className="brand-wrap" to="/home">
            <img className="logo" src={logo} alt="Evara Dashboard" />
          </Link>
          <div>
            <button className="btn btn-icon btn-aside-minimize">
              <i className="text-muted material-icons md-menu_open"></i>
            </button>
          </div>
        </div>
        <nav>
          <ul className="menu-aside">
            
            <li className="menu-item active">
              <Link className="menu-link" to="/home">
                <i className="icon material-icons md-home"></i>
                <span className="text">Dashboard</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link className="menu-link" to="/pagebuyerslist">
                <i className="icon material-icons md-person"></i>
                <span className="text">Buyers</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link className="menu-link" to="/enquirylist">
                <i className="icon material-icons md-support_agent"></i>
                <span className="text">Enquiry</span>
              </Link>
            </li>
            <li className="menu-item ">
              <Link className="menu-link" to="/pageproductslist">
                <i className="icon material-icons md-store"></i>
                <span className="text">Sampling</span>
              </Link>
            </li>
            <li className="menu-item ">
              <Link className="menu-link" to="/pagesamplingdev">
                <i className=" icon material-icons md-build"></i>
                <span className="text">Sampling Development</span>
              </Link>
            </li>
            {/* <li className="menu-item">
              <Link className="menu-link" to="/pageformprofit">
                <i className="icon material-icons md-monetization_on"></i>
                <span className="text">Set Profit</span>
              </Link>
            </li> */}
            {/* <li className="menu-item">
              <Link className="menu-link" to="/pageorders">
                <i className="icon material-icons md-shopping_cart"></i>
                <span className="text">Purchase</span>
              </Link>
            </li> */}
            <li className="menu-item">
              <Link className="menu-link" to="/pageorders">
                <i className="icon material-icons md-receipt_long"></i>
                <span className="text">Order Management</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link className="menu-link" to="/pageinvoicelist">
                <i className="icon material-icons md-receipt"></i>
                <span className="text">Invoices</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link className="menu-link" to="/pageformshipping">
                <i className="icon material-icons md-local_shipping"></i>
                <span className="text">Shipment</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link className="menu-link" to="/pagesellerslist">
                <i className="icon material-icons md-corporate_fare"></i>
                <span className="text">Manufacturers</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link className="menu-link" to="/rackmanagement">
                <i className="icon material-icons md-all_inbox"></i>
                <span className="text">Rack Management</span>
              </Link>
            </li>
            <li className="menu-item  has-submenu">
              <Link className="menu-link" to="#">
                <i className="icon material-icons md-file_copy"></i>
                <span className="text">Reports</span>
              </Link>
              <div className="submenu">
                <Link to="/invoicereport">Invoice</Link>
                <Link to="/purchasereport">Purchase Order</Link>
                <Link to="/shipmentreport">Shipment</Link>
              </div>
            </li>

            {/* <li className="menu-item has-submenu">
              <a className="menu-link" href="page-form-product-1.html">
                <i className="icon material-icons md-add_box"></i>
                <span className="text">Add product</span>
              </a>
              <div className="submenu">
                <a href="page-form-product-1.html">Add product 1</a>
                <a href="page-form-product-2.html">Add product 2</a>
                <a href="page-form-product-3.html">Add product 3</a>
                <a href="page-form-product-4.html">Add product 4</a>
              </div>
            </li> */}
            {/* <li className="menu-item has-submenu">
              <a className="menu-link" href="#">
                <i className="icon material-icons md-person"></i>
                <span className="text">Account</span>
              </a>
              <div className="submenu">
                <a href="page-account-login.html">User login</a>
                <a href="page-account-register.html">User registration</a>
                <a href="page-error-404.html">Error 404</a>
              </div>
            </li> */}
            {/* <li className="menu-item">
              <a className="menu-link" href="page-reviews.html">
                <i className="icon material-icons md-comment"></i>
                <span className="text">Reviews</span>
              </a>
            </li>
            <li className="menu-item">
              <a className="menu-link" href="page-brands.html">
                <i className="icon material-icons md-stars"></i>
                <span className="text">Brands</span>
              </a>
            </li>
            <li className="menu-item">
              <a className="menu-link" disabled="" href="#">
                <i className="icon material-icons md-pie_chart"></i>
                <span className="text">Statistics</span>
              </a>
            </li> */}
          </ul>
          <hr />
          <ul className="menu-aside">
            <li className="menu-item">
              <Link className="menu-link" to="/">
                <i className="material-icons md-exit_to_app"></i>
                <span className="text">Logout</span>
              </Link>
              {/* <div className="submenu">
                <a href="page-settings-1.html">Setting sample 1</a>
                <a href="page-settings-2.html">Setting sample 2</a>
              </div> */}
            </li>
            {/* <li className="menu-item">
              <a className="menu-link" href="page-blank.html">
                <i className="icon material-icons md-local_offer"></i>
                <span className="text"> Starter page</span>
              </a>
            </li> */}
          </ul>
        </nav>
      </aside>
    </>
  );
}
export default Sidebar;

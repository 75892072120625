import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link } from "react-router-dom";

function Pageorders() {
  const data = [
    {
      id: 452,
      buyerName: "Devon Lane",
      manufacturerName: "Manufacturer A",
      price: "₹948.55",
      status: "Invoiced",
      date: "07.05.2022",
    },
    {
      id: 453,
      buyerName: "Alice Smith",
      manufacturerName: "Manufacturer B",
      price: "₹1200.00",
      status: "Draft",
      date: "08.05.2022",
    },
    {
      id: 454,
      buyerName: "John Doe",
      manufacturerName: "Manufacturer C",
      price: "₹500.75",
      status: "Cancelled",
      date: "09.05.2022",
    },
    {
      id: 455,
      buyerName: "Jane Roe",
      manufacturerName: "Manufacturer D",
      price: "₹750.50",
      status: "Invoiced",
      date: "10.05.2022",
    },
    {
      id: 456,
      buyerName: "Richard Nixon",
      manufacturerName: "Manufacturer E",
      price: "₹1200.00",
      status: "Returned",
      date: "11.05.2022",
    },
    {
      id: 457,
      buyerName: "George Bush",
      manufacturerName: "Manufacturer F",
      price: "₹200.00",
      status: "Draft",
      date: "12.05.2022",
    },
    {
      id: 458,
      buyerName: "Barack Obama",
      manufacturerName: "Manufacturer G",
      price: "₹300.00",
      status: "Invoiced",
      date: "13.05.2022",
    },
    {
      id: 459,
      buyerName: "Donald Trump",
      manufacturerName: "Manufacturer H",
      price: "₹850.25",
      status: "Invoiced",
      date: "14.05.2022",
    },
    {
      id: 460,
      buyerName: "Joe Biden",
      manufacturerName: "Manufacturer I",
      price: "₹950.50",
      status: "Invoiced",
      date: "15.05.2022",
    },
    {
      id: 461,
      buyerName: "Kamala Harris",
      manufacturerName: "Manufacturer J",
      price: "₹1200.00",
      status: "Draft",
      date: "16.05.2022",
    },
  ];

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Purchase Orders</h2>
              {/* <p>Lorem ipsum dolor sit amet.</p> */}
            </div>
            <div>
              <a className="btn btn-light rounded font-md mx-2" href="#">
                Export
              </a>
              <Link
                className="btn btn-primary btn-sm rounded"
                to="/pageformpurchaseorder"
              >
                Create new PO
              </Link>
            </div>
          </div>
          <Row>
            <Col md={9}>
              <div className="card mb-4">
                <header className="card-header">
                  <Row className="gx-3">
                    <Col lg={4} md={6} className="me-auto">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Search..."
                      />
                    </Col>
                    <Col lg={2} md={3} className="col-6">
                      <select className="form-select">
                        <option>Status</option>
                        <option>Shipped</option>
                        <option>Invoiced</option>
                        <option>Paid</option>
                        <option>Draft</option>
                        <option>Cancelled</option>
                      </select>
                    </Col>
                    <Col lg={2} md={3} sm={6}>
                      <select className="form-select">
                        <option>Show 20</option>
                        <option>Show 30</option>
                        <option>Show 40</option>
                      </select>
                    </Col>
                  </Row>
                </header>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>PO No</th>
                          <th>Buyer name</th>
                          <th>Manufacturer name</th>
                          <th>Price</th>
                          <th>Status</th>
                          <th>Due Date</th>
                          <th > Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item) => (
                          <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>
                              <b>{item.buyerName}</b>
                            </td>
                            <td>{item.manufacturerName}</td>
                            <td>{item.price}</td>
                            <td>
                              <span
                                className={`badge rounded-pill alert-${
                                  item.status === "Invoiced"
                                    ? "success"
                                    : "warning"
                                }`}
                              >
                                {item.status}
                              </span>
                            </td>
                            <td>{item.date}</td>
                            <td>
                              <div className="dropdown">
                                <a
                                  className="btn btn-light rounded btn-sm font-sm"
                                  href="#"
                                  data-bs-toggle="dropdown"
                                >
                                  <i className="material-icons md-more_horiz"></i>
                                </a>
                                <div className="dropdown-menu">
                                  <a className="dropdown-item" href="#">
                                    Create Invoice
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    Change Status
                                  </a>
                                  <a
                                    className="dropdown-item text-danger"
                                    href="#"
                                  >
                                    Delete
                                  </a>
                                </div>
                              </div>
                              <Link
                                className="btn btn-md rounded font-sm"
                                to="/pageordersdetail"
                              >
                                Detail
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="mb-3">Filter by</h5>
                  <form>
                    <div className="mb-4">
                      <label className="form-label" for="order_id">
                        Order ID
                      </label>
                      <input
                        className="form-control"
                        id="order_id"
                        type="text"
                        placeholder="Type here"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="form-label" for="order_customer">
                        Manufacturer
                      </label>
                      <input
                        className="form-control"
                        id="order_customer"
                        type="text"
                        placeholder="Type here"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="form-label" for="order_customer_1">
                        Buyer
                      </label>
                      <input
                        className="form-control"
                        id="order_customer_1"
                        type="text"
                        placeholder="Type here"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="form-label">Order Status</label>
                      <select className="form-select">
                        <option>Invoiced</option>
                        <option>Draft</option>
                      </select>
                    </div>
                    <div className="mb-4">
                      <label className="form-label" for="order_total">
                        Total
                      </label>
                      <input
                        className="form-control"
                        id="order_total"
                        type="text"
                        placeholder="Type here"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="form-label" for="order_created_date">
                        Date Due
                      </label>
                      <input
                        className="form-control"
                        id="order_created_date"
                        type="text"
                        placeholder="Type here"
                      />
                    </div>
                    
                  </form>
                </div>
              </div>
            </Col>
          </Row>
          <div className="pagination-area mt-15 mb-50">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-start">
                <li className="page-item active">
                  <a className="page-link" href="#">
                    01
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    02
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    03
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link dot" href="#">
                    ...
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    16
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    <i className="material-icons md-chevron_right"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default Pageorders;

import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import brand3 from "../../assets/imgs/brands/brand-3.jpg";
import map from "../../assets/imgs/misc/map.jpg";
import item1 from "../../assets/imgs/items/1.jpg";
import item2 from "../../assets/imgs/items/2.jpg";
import item3 from "../../assets/imgs/items/3.jpg";
import item4 from "../../assets/imgs/items/4.jpg";
import item5 from "../../assets/imgs/items/5.jpg";
import item6 from "../../assets/imgs/items/6.jpg";
import item7 from "../../assets/imgs/items/7.jpg";
import item8 from "../../assets/imgs/items/8.jpg";
import item9 from "../../assets/imgs/items/9.jpg";
import item10 from "../../assets/imgs/items/10.jpg";
import item11 from "../../assets/imgs/items/11.jpg";
import item12 from "../../assets/imgs/items/12.jpg";

function Pagesellerdetail(){
    return(
        <>
        <Sidebar/>
        <main className="main-wrap">
        <Header/>

        <section className="content-main">
        <div className="content-header"><a href="javascript:history.back()"><i className="material-icons md-arrow_back"></i> Go back</a></div>
        <div className="card mb-4">
          <div className="card-header bg-primary" style={{height:'150px'}}></div>
          <div className="card-body">
            <Row>
              <div className="col-xl col-lg flex-grow-0" style={{flexBasis:'230px'}}>
                <div className="img-thumbnail shadow w-100 bg-white position-relative text-center" style={{height:'190px', width:'200px', marginTop:'-120px'}}><img className="center-xy img-fluid" src={brand3} alt="Logo Brand"/></div>
              </div>
              <div className="col-xl col-lg">
                <h3>Cocorico sports shop</h3>
                <p>3891 Ranchview Dr. Richardson, California 62639</p>
              </div>
              <div className="col-xl-4 text-md-end">
                <select className="form-select w-auto d-inline-block">
                  <option>Actions</option>
                  <option>Mark as inactive</option>
                  <option>Edit</option>
                </select>
                {/* <a className="btn btn-primary" href="#">View live<i className="material-icons md-launch"></i></a> */}
              </div>
              <hr className="my-4"/>
              <Row className="g-4">
                <Col md={12} lg={4} xl={3}>
                  <article className="box">
                    <p className="mb-0 text-muted">Total Maufactured Orders:</p>
                    <h5 className="text-success">238</h5>
                    <p className="mb-0 text-muted">Revenue:</p>
                    <h5 className="text-success mb-0">₹2380</h5>
                    <p className="mb-0 text-muted">In Progress Payments to clear:</p>
                    <h5 className="text-success mb-0">₹230</h5>
                  </article>
                </Col>
                <Col sm={6} lg={4} xl={3}>
                  <h6>Contacts</h6>
                  <p>Manager: Jerome Bell<br/>                                info@example.com<br/>                                (229) 555-0109, (808) 555-0111</p>
                </Col>
                <Col sm={6} lg={4} xl={3}>
                  <h6>Address</h6>
                  <p>Country: California<br/>                                Address: Ranchview Dr. Richardson<br/>                                Postal code: 62639</p>
                </Col>
                
                </Row>
            </Row>
          </div>
        </div>
        <div className="card mb-4">
          <div className="card-body">
            <h5 className="card-title">Manufacturer Orders</h5>
            <Row>
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Buyer name</th>
                        <th>Price</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th className="text-end"> Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>452</td>
                        <td><b>Devon Lane</b></td>
                        <td>₹948.55</td>
                        <td><span className="badge rounded-pill alert-primary">Yet to Start</span></td>
                        <td>07.05.2022</td>
                        <td className="text-end"><a className="btn btn-md rounded font-sm" href="#">Detail</a>
                          <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                            <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>789</td>
                        <td><b>Guy Hawkins</b></td>
                        <td>₹0.00</td>
                        <td><span className="badge rounded-pill alert-danger">Cancelled</span></td>
                        <td>25.05.2022</td>
                        <td className="text-end"><a className="btn btn-md rounded font-sm" href="#">Detail</a>
                          <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                            <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>478</td>
                        <td><b>Leslie Alexander</b></td>
                        <td>₹293.01</td>
                        <td><span className="badge rounded-pill alert-warning">In Progress</span></td>
                        <td>18.05.2022</td>
                        <td className="text-end"><a className="btn btn-md rounded font-sm" href="#">Detail</a>
                          <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                            <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>589</td>
                        <td><b>Albert Flores</b></td>
                        <td>₹105.55</td>
                        <td><span className="badge rounded-pill alert-warning">In Progress</span></td>
                        <td>07.02.2022</td>
                        <td className="text-end"><a className="btn btn-md rounded font-sm" href="#">Detail</a>
                          <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                            <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>345</td>
                        <td><b>Eleanor Pena</b></td>
                        <td>₹779.58</td>
                        <td><span className="badge rounded-pill alert-success">Received</span></td>
                        <td>18.03.2022</td>
                        <td className="text-end"><a className="btn btn-md rounded font-sm" href="#">Detail</a>
                          <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                            <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>456</td>
                        <td><b>Dianne Russell</b></td>
                        <td>₹576.28</td>
                        <td><span className="badge rounded-pill alert-success">Received</span></td>
                        <td>23.04.2022</td>
                        <td className="text-end"><a className="btn btn-md rounded font-sm" href="#">Detail</a>
                          <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                            <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>768</td>
                        <td><b>Savannah Nguyen</b></td>
                        <td>₹589.99</td>
                        <td><span className="badge rounded-pill alert-success">Received</span></td>
                        <td>18.05.2022</td>
                        <td className="text-end"><a className="btn btn-md rounded font-sm" href="#">Detail</a>
                          <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                            <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>977</td>
                        <td><b>Kathryn Murphy</b></td>
                        <td>₹169.43</td>
                        <td><span className="badge rounded-pill alert-success">Received</span></td>
                        <td>23.03.2022</td>
                        <td className="text-end"><a className="btn btn-md rounded font-sm" href="#">Detail</a>
                          <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                            <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>687</td>
                        <td><b>Jacob Jones</b></td>
                        <td>₹219.78</td>
                        <td><span className="badge rounded-pill alert-success">Received</span></td>
                        <td>07.05.2022</td>
                        <td className="text-end"><a className="btn btn-md rounded font-sm" href="#">Detail</a>
                          <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                            <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>688</td>
                        <td><b>Jacob Jones</b></td>
                        <td>₹219.78</td>
                        <td><span className="badge rounded-pill alert-success">Received</span></td>
                        <td>07.05.2022</td>
                        <td className="text-end"><a className="btn btn-md rounded font-sm" href="#">Detail</a>
                          <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                            <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              
            </Row>
          </div>
        </div>
        <div className="pagination-area mt-30 mb-50">
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-start">
              <li className="page-item active"><a className="page-link" href="#">01</a></li>
              <li className="page-item"><a className="page-link" href="#">02</a></li>
              <li className="page-item"><a className="page-link" href="#">03</a></li>
              <li className="page-item"><a className="page-link dot" href="#">...</a></li>
              <li className="page-item"><a className="page-link" href="#">16</a></li>
              <li className="page-item"><a className="page-link" href="#"><i className="material-icons md-chevron_right"></i></a></li>
            </ul>
          </nav>
        </div>
      </section>

        <Footer/>
        </main>
        </>
    );
}
export default Pagesellerdetail;
import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";

function Pagecategories(){
    return(
        <>
        
        <Sidebar/>
        <main className="main-wrap">
        <Header/>

        <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">Categories</h2>
            <p>Add, edit or delete a category</p>
          </div>
          <div>
            <input className="form-control bg-white" type="text" placeholder="Search Categories"/>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <Row>
              <Col md={3}>
                <form></form>
                <div className="mb-4">
                  <label className="form-label" for="product_name">Name</label>
                  <input className="form-control" id="product_name" type="text" placeholder="Type here"/>
                </div>
                <div className="mb-4">
                  <label className="form-label" for="product_slug">Slug</label>
                  <input className="form-control" id="product_slug" type="text" placeholder="Type here"/>
                </div>
                <div className="mb-4">
                  <label className="form-label">Parent</label>
                  <select className="form-select">
                    <option>Clothes</option>
                    <option>T-Shirts</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label className="form-label">Description</label>
                  <textarea className="form-control" placeholder="Type here"></textarea>
                </div>
                <div className="d-grid">
                  <button className="btn btn-primary">Create category</button>
                </div>
              </Col>
              <Col md={9}>
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th className="text-center">
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value=""/>
                          </div>
                        </th>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Slug</th>
                        <th>Order</th>
                        <th className="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value=""/>
                          </div>
                        </td>
                        <td>21</td>
                        <td><b>Men clothes</b></td>
                        <td>Men clothes</td>
                        <td>/men</td>
                        <td>1</td>
                        <td className="text-end">
                          <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                            <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value=""/>
                          </div>
                        </td>
                        <td>2</td>
                        <td><b>Women fashion</b></td>
                        <td>Fashions for Women</td>
                        <td>/women</td>
                        <td>2</td>
                        <td className="text-end">
                          <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                            <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value=""/>
                          </div>
                        </td>
                        <td>3</td>
                        <td><b>Kids clothes</b></td>
                        <td>Clothes for kids</td>
                        <td>/kids</td>
                        <td>3</td>
                        <td className="text-end">
                          <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                            <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center"><i className="material-icons md-subdirectory_arrow_right text-muted"></i></td>
                        <td>4</td>
                        <td><b>Hot Gifts</b></td>
                        <td>Hot Gifts</td>
                        <td>/gifts</td>
                        <td>4</td>
                        <td className="text-end">
                          <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                            <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center"><i className="material-icons md-subdirectory_arrow_right text-muted"></i></td>
                        <td>5</td>
                        <td><b>Electronics</b></td>
                        <td>Electronics</td>
                        <td>/electr</td>
                        <td>5</td>
                        <td className="text-end">
                          <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                            <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value=""/>
                          </div>
                        </td>
                        <td>6</td>
                        <td><b>Accessories</b></td>
                        <td>Accessories</td>
                        <td>/accessories</td>
                        <td>6</td>
                        <td className="text-end">
                          <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                            <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value=""/>
                          </div>
                        </td>
                        <td>7</td>
                        <td><b>Jewellery</b></td>
                        <td>Jewellery</td>
                        <td>/jewel</td>
                        <td>7</td>
                        <td className="text-end">
                          <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                            <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value=""/>
                          </div>
                        </td>
                        <td>8</td>
                        <td><b>Interiors</b></td>
                        <td>Interiors</td>
                        <td>/interior</td>
                        <td>8</td>
                        <td className="text-end">
                          <div className="dropdown"><a className="btn btn-light rounded btn-sm font-sm" href="#" data-bs-toggle="dropdown"><i className="material-icons md-more_horiz"></i></a>
                            <div className="dropdown-menu"><a className="dropdown-item" href="#">View detail</a><a className="dropdown-item" href="#">Edit info</a><a className="dropdown-item text-danger" href="#">Delete</a></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>

        <Footer/>
        </main>
        </>
    );
}
export default Pagecategories;
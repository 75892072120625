import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import item1 from "../../assets/imgs/items/1.jpg";
import item2 from "../../assets/imgs/items/2.jpg";
import item3 from "../../assets/imgs/items/3.jpg";
import item4 from "../../assets/imgs/items/4.jpg";
import item5 from "../../assets/imgs/items/5.jpg";
import item6 from "../../assets/imgs/items/6.jpg";
import { Link } from "react-router-dom";

function EnquiryList() {
    const garmentEnquiryData = [
        {
          buyer: "Elsa Pena",
          description: "Sample inquiry for quality and color matching of cotton T-shirt",
          sample_refId: "Sref-1234",
          remarks: "Ready for initial review",
          status: "Converted"
        },
        {
          buyer: "John Smith",
          description: "Inquiry regarding color accuracy and stitching for Rayon Tee",
          sample_refId: "Sref-1235",
          remarks: "Pending additional fabric check",
          status: "Callback"
        },
        {
          buyer: "Sarah Johnson",
          description: "Checking print quality for Graphic Hoodie",
          sample_refId: "Sref-1236",
          remarks: "Waiting for approval from buyer",
          status: "Callback Cancelled"
        },
        {
          buyer: "Michael Lee",
          description: "Inspection for Stylish Sweatpants stitching and durability",
          sample_refId: "Sref-1237",
          remarks: "All specifications met",
          status: "Converted"
        },
        {
          buyer: "Laura Kim",
          description: "Assessment for Cool Cap fabric and fit",
          sample_refId: "Sref-1238",
          remarks: "Buyer requested changes",
          status: "Callback"
        },
        {
          buyer: "James Brown",
          description: "Review for Summer Shorts stitching and comfort",
          sample_refId: "Sref-1239",
          remarks: "Further color testing needed",
          status: "Callback Cancelled"
        },
        {
          buyer: "Emily White",
          description: "Quality and durability test for Basic Black Tee",
          sample_refId: "Sref-1240",
          remarks: "Meets standard requirements",
          status: "Converted"
        },
        {
          buyer: "Chris Green",
          description: "Testing fabric quality for Denim Jacket",
          sample_refId: "Sref-1241",
          remarks: "Pending fabric sample",
          status: "Callback"
        },
        {
          buyer: "Anna Taylor",
          description: "Preliminary inspection for Sporty Sneakers",
          sample_refId: "Sref-1242",
          remarks: "Needs revision in stitching",
          status: "Callback Cancelled"
        },
        {
          buyer: "David Wilson",
          description: "Evaluation for Lightweight Windbreaker material and stitching",
          sample_refId: "Sref-1243",
          remarks: "Approved for shipment",
          status: "Converted"
        }
      ];
      

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Enquiry List</h2>
              {/* <p>Lorem ipsum dolor sit amet.</p> */}
            </div>
            <div>
              <a className="btn btn-light rounded font-md mx-2" href="#">
                Export
              </a>
              <a className="btn btn-light rounded font-md mx-2" href="#">
                Import
              </a>
              <Link
                className="btn btn-primary btn-sm rounded"
                to="/enquiryadd"
              >
                Create new
              </Link>
            </div>
          </div>
          <div className="card mb-4">
            <header className="card-header">
              <div className="row align-items-center">
                {/* <div className="col col-check flex-grow-0">
                  <div className="form-check ms-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                  </div>
                </div> */}
                <div className="col-md-2 col-12 mb-md-0 mb-3">
                  <select className="form-select">
                    <option selected="">Search by</option>
                    <option>Buyer</option>
                    <option>Description</option>
                    <option>Sample Ref Id</option>
                    <option>Remarks</option>
                    <option>Status</option>
          
                  </select>
                </div>
                <div className="col-md-3 col-6  me-auto">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search..."
                  />
                </div>
                <div className="col-md-2 col-6">
                  <select className="form-select">
                    <option selected="">Status</option>
                    <option>Converted</option>
                    <option>Callback</option>
                    <option>Callback Cancelled</option>
                  </select>
                </div>
              </div>
            </header>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Buyer</th>
                      <th>Description</th>
                      <th>Sample Ref Id</th>
                      <th>Remarks</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {garmentEnquiryData.map((product) => (
                      <tr key={product.ref_id}>
                        <td>
                          <a className="itemside" href="#">
                            <div className="info">
                              <h6 className="mb-0">{product.buyer}</h6>
                            </div>
                          </a>
                        </td>
                        <td>
                          <a className="itemside" href="#">
                            <div className="info pl-3">
                              <h6 className="mb-0 title">{product.description}</h6>
                            
                            </div>
                          </a>
                        </td>
                        <td>
                          <a className="itemside" href="#">
                            <div className="info pl-3">
                              <h6 className="mb-0 title">
                                {product.sample_refId}
                              </h6>
                            </div>
                          </a>
                        </td>
                        <td>{product.remarks}</td>
            
                        <td>
                          <span
                            className={
                              product.status == "Converted"
                                ? "badge rounded-pill alert-success"
                                : product.status == "Callback"
                                ? "badge rounded-pill alert-primary"
                                : "badge rounded-pill alert-warning"
                            }
                          >
                            {product.status}
                          </span>
                        </td>
                        {/* <td className="text-end">
                          <div className="dropdown">
                            <a
                              className="btn btn-light rounded btn-sm font-sm"
                              href="#"
                              data-bs-toggle="dropdown"
                            >
                              <i className="material-icons md-more_horiz"></i>
                            </a>
                            <div className="dropdown-menu">
                              <a className="dropdown-item" href="#">
                                Change Status
                              </a>
                              <a className="dropdown-item" href="#">
                                Ship Sample
                              </a>
                              <a className="dropdown-item" href="#">
                                Create PO
                              </a>
                              <a className="dropdown-item text-danger" href="#">
                                Delete
                              </a>
                            </div>
                          </div>
                          <Link
                            className="btn btn-sm btn-brand rounded font-sm"
                            to="#"
                          >
                            View details
                          </Link>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="pagination-area mt-30 mb-50">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-start">
                <li className="page-item Yet to Start">
                  <a className="page-link" href="#">
                    01
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    02
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    03
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link dot" href="#">
                    ...
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    16
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    <i className="material-icons md-chevron_right"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default EnquiryList;

import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import uploads from "../../assets/imgs/theme/upload.svg";
import { Link } from "react-router-dom";

function Pageformpurchaseorder() {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <Row>
            <Col md={12}>
              <div className="content-header">
                <h2 className="content-title">Create New PO</h2>
                <div>
                  {/* <button className="btn btn-light rounded font-sm mr-5 text-body hover-up">Save to draft</button> */}
                  <Link
                    to="/pageorders"
                    className="btn btn-md rounded font-sm hover-up"
                  >
                    Create PO
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="card mb-4">
                {/* <div className="card-header">
                
                </div> */}
                <div className="card-body">
                  <form>
                    {/* <div className="mb-4">
                    <label className="form-label" for="product_name">Sample title</label>
                    <input className="form-control" id="product_name" type="text" placeholder="Type here"/>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Full description</label>
                    <textarea className="form-control" placeholder="Type here" rows="4"></textarea>
                  </div> */}
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label">PO No</label>
                          <div className="row gx-2"></div>
                          <input
                            className="form-control"
                            placeholder="Type Here"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label">Buyer</label>
                          <select className="form-select">
                            <option> Self</option>
                            <option> Elsa</option>
                            <option> Rodrick</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <label className="form-label">Manufacturer</label>
                        <select className="form-select">
                          <option> Fabrics & co.</option>
                          <option> Mena Clothings</option>
                          <option> Rebel Materials</option>
                        </select>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label">Order Deadline</label>
                          <input
                            className="form-control"
                            id="product_name"
                            type="date"
                            placeholder="Date"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label">
                            Manufacturer Delivery Date
                          </label>
                          <input
                            className="form-control"
                            id="product_name"
                            type="date"
                            placeholder="Date"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label">Notes</label>
                          <textarea
                            className="form-control"
                            id="product_name"
                            placeholder="Notes"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="card mb-4">
                <div className="card-header">
                  <h4>Order Items</h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Quantity</th>
                          <th>Price Per piece</th>
                          <th>Tax</th>
                          <th> Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>
                            <input
                              className="form-control"
                              id="product_name"
                              type="text"
                              placeholder="Sample / Item"
                              value="Tshirt #1003"
                            />
                          </th>
                          <th>
                            <input
                              className="form-control"
                              id="product_name"
                              type="number"
                              placeholder="Quantity"
                              value="300"
                            />
                          </th>
                          <th>
                            <input
                              className="form-control"
                              id="product_name"
                              type="number"
                              placeholder="Price"
                              value="100"
                            />
                          </th>
                          <th>
                            <input
                              className="form-control"
                              id="product_name"
                              type="number"
                              placeholder="Tax%"
                              value="0"
                            />
                          </th>
                          <th> 30000.00</th>
                        </tr>
                        <tr>
                          <th>
                            <input
                              className="form-control"
                              id="product_name"
                              type="text"
                              placeholder="Sample / Item"
                            />
                          </th>
                          <th>
                            <input
                              className="form-control"
                              id="product_name"
                              type="number"
                              placeholder="Quantity"
                            />
                          </th>
                          <th>
                            <input
                              className="form-control"
                              id="product_name"
                              type="number"
                              placeholder="Price"
                            />
                          </th>
                          <th>
                            <input
                              className="form-control"
                              id="product_name"
                              type="number"
                              placeholder="Tax%"
                            />
                          </th>
                          <th> 0.00</th>
                        </tr>
                        <tr>
                          <th className="text-end" colSpan={4}>
                            Subtotal:
                          </th>
                          <th> 30000.00</th>
                        </tr>
                        <tr>
                          <th className="text-end" colSpan={4}>
                            Tax:
                          </th>
                          <th> 0.00</th>
                        </tr>
                        <tr>
                          <th className="text-end" colSpan={4}>
                            Shipping:
                          </th>
                          <th> 0.00</th>
                        </tr>
                        <tr>
                          <th className="text-end" colSpan={4}>
                            Grand Total:
                          </th>
                          <th> 30000.00</th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default Pageformpurchaseorder;

import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import uploads from "../../assets/imgs/theme/upload.svg";
import { Chrono } from "react-chrono";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

function Pageformshipping() {
  const shipments = [
    {
      cardTitle: "Order Placed",
      date: "2023-10-01",
      cardDetailedText: "Your order has been placed successfully.",
    },
    {
      cardTitle: "Shipped",
      date: "2023-10-02",
      cardDetailedText: "Your order has been shipped.",
    },
    {
      cardTitle: "Out for Delivery",
      date: "2023-10-03",
      cardDetailedText: "Your order is out for delivery.",
    },
    {
      cardTitle: "Delivered",
      date: "2023-10-04",
      cardDetailedText: "Your order has been delivered.",
    },
  ];
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <Row>
            <Col md={12}>
              <div className="content-header">
                <h2 className="content-title">Shipment Tracking</h2>
                <div>
                  {/* <button className="btn btn-light rounded font-sm mr-5 text-body hover-up">Save to draft</button> */}
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="card mb-4">
                <div className="card-header">
                  <h4>Tracking</h4>
                </div>
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="mb-4">
                          <label className="form-label">Tracking Number</label>
                          <input
                            className="form-control"
                            placeholder="Type Here"
                            type="number"
                            value="12343211"
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="mb-4">
                          <label className="form-label">Order Number</label>
                          <input
                            className="form-control"
                            placeholder="Type Here"
                            type="number"
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <label className="form-label">&nbsp;</label>
                        <button className="form-control btn btn-md rounded font-sm hover-up ">
                          Track
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="card mb-30">
                <div className="card-header">
                  <Row>
                    <Col lg={6}>
                      <h4>
                        Tracking Details <br />{" "}
                      </h4>
                    </Col>
                    <Col lg={2} style={{ margin: "auto" }}>
                      <button className="form-control btn btn-md rounded font-sm hover-up ">
                        View Shipment Slip
                      </button>
                    </Col>
                    <Col lg={2} style={{ margin: "auto" }}>
                      <button className="form-control btn btn-md rounded font-sm hover-up ">
                        Email Tracking Details
                      </button>
                    </Col>
                    <Col lg={2} style={{ margin: "auto" }}>
                      <button className="form-control btn btn-md rounded font-sm hover-up ">
                        Sync Status
                      </button>
                    </Col>
                  </Row>
                </div>
                <div className="card-body">
                  <Row className="mb-5">
                    <Col><h3>Tracking Number: <span style={{fontWeight:"100"}}>#12343211</span></h3></Col>
                    <Col>
                      <h3 className="text-center">Shipment Cost: <span style={{fontWeight:"100"}}>150</span></h3>
                    </Col>
                    <Col>
                      <h3 className="text-end">
                        Expected Delivery: <span style={{fontWeight:"100"}}>15.10.2024</span>
                      </h3>
                    </Col>
                  </Row>
                  <Chrono
                    className="mt-5"
                    items={shipments}
                    mode="VERTICAL_ALTERNATING"
                    cardHeight={50}
                    cardWidth={280}
                    disableToolbar={true}
                    enableLayoutSwitch={false}
                    useReadMore={false}
                  />

                  {/* <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>Date Time</th>
                          <th>Location</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>09.10.2024</td>
                          <td>Delhi</td>
                          <td>In Transit</td>
                        </tr>
                        <tr>
                          <td>08.10.2024</td>
                          <td>Tirupur</td>
                          <td>Dispatched</td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
                </div>
              </div>
            </Col>
          </Row>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default Pageformshipping;

import React, { useRef, useState, useEffect, useCallback } from 'react' 
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import item1 from "../../assets/imgs/items/1.jpg";
import item2 from "../../assets/imgs/items/2.jpg";
import item3 from "../../assets/imgs/items/3.jpg";
import item4 from "../../assets/imgs/items/4.jpg";
import item5 from "../../assets/imgs/items/5.jpg";
import item6 from "../../assets/imgs/items/6.jpg";
import { Link } from "react-router-dom";

function Pageproductslist() {
  const productsTotal = [
    {
      product_name: "Pena Printed Sample",
      ref_id: "Sref-1234",
      img: "https://via.placeholder.com/150/0000FF/FFFFFF?text=T-shirt",
      type: "T-shirt",
      status: "Yet to Start",
      createdate: "08.07.2020",
      buyer: "Elsa Pena",
      manufacturer: "Mena Fabrics",
      seller_id: "#321",
      buyer_id: "#432",
      order_id: "#1001", // New field
    },
    {
      product_name: "Classic White Tee",
      ref_id: "Sref-1235",
      img: "https://via.placeholder.com/150/FFFFFF/000000?text=White+Tee",
      type: "T-shirt",
      status: "Delivered",
      createdate: "09.15.2021",
      buyer: "John Smith",
      manufacturer: "Fashion Co.",
      seller_id: "#322",
      buyer_id: "#433",
      order_id: "#1002", // New field
    },
    // Additional products with order_id...
    {
      product_name: "Graphic Hoodie",
      ref_id: "Sref-1236",
      img: "https://via.placeholder.com/150/FF5733/FFFFFF?text=Hoodie",
      type: "Hoodie",
      status: "Shipped",
      createdate: "10.10.2022",
      buyer: "Sarah Johnson",
      manufacturer: "Hoodie House",
      seller_id: "#323",
      buyer_id: "#434",
      order_id: "#1003", // New field
    },
    {
      product_name: "Stylish Sweatpants",
      ref_id: "Sref-1237",
      img: "https://via.placeholder.com/150/808080/FFFFFF?text=Sweatpants",
      type: "Pants",
      status: "Yet to Start",
      createdate: "12.01.2021",
      buyer: "Michael Lee",
      manufacturer: "Pants Plus",
      seller_id: "#324",
      buyer_id: "#435",
      order_id: "#1004", // New field
    },
    {
      product_name: "Cool Cap",
      ref_id: "Sref-1238",
      img: "https://via.placeholder.com/150/FFD700/FFFFFF?text=Cap",
      type: "Accessory",
      status: "Yet to Start",
      createdate: "03.22.2023",
      buyer: "Laura Kim",
      manufacturer: "Caps Unlimited",
      seller_id: "#325",
      buyer_id: "#436",
      order_id: "#1005", // New field
    },
    {
      product_name: "Summer Shorts",
      ref_id: "Sref-1239",
      img: "https://via.placeholder.com/150/FFB6C1/FFFFFF?text=Shorts",
      type: "Shorts",
      status: "Yet to Start",
      createdate: "05.05.2022",
      buyer: "James Brown",
      manufacturer: "Shorts Factory",
      seller_id: "#326",
      buyer_id: "#437",
      order_id: "#1006", // New field
    },
    {
      product_name: "Basic Black Tee",
      ref_id: "Sref-1240",
      img: "https://via.placeholder.com/150/000000/FFFFFF?text=Black+Tee",
      type: "T-shirt",
      status: "Yet to Start",
      createdate: "11.11.2021",
      buyer: "Emily White",
      manufacturer: "Tee Time",
      seller_id: "#327",
      buyer_id: "#438",
      order_id: "#1007", // New field
    },
    {
      product_name: "Denim Jacket",
      ref_id: "Sref-1241",
      img: "https://via.placeholder.com/150/1E90FF/FFFFFF?text=Denim+Jacket",
      type: "Jacket",
      status: "Yet to Start",
      createdate: "07.14.2022",
      buyer: "Chris Green",
      manufacturer: "Denim Dreams",
      seller_id: "#328",
      buyer_id: "#439",
      order_id: "#1008", // New field
    },
    {
      product_name: "Sporty Sneakers",
      ref_id: "Sref-1242",
      img: "https://via.placeholder.com/150/32CD32/FFFFFF?text=Sneakers",
      type: "Shoes",
      status: "Yet to Start",
      createdate: "08.18.2023",
      buyer: "Anna Taylor",
      manufacturer: "Sneaker World",
      seller_id: "#329",
      buyer_id: "#440",
      order_id: "#1009", // New field
    },
    {
      product_name: "Lightweight Windbreaker",
      ref_id: "Sref-1243",
      img: "https://via.placeholder.com/150/FF4500/FFFFFF?text=Windbreaker",
      type: "Jacket",
      status: "Yet to Start",
      createdate: "09.30.2021",
      buyer: "David Wilson",
      manufacturer: "Windbreaker Co.",
      seller_id: "#330",
      buyer_id: "#441",
      order_id: "#1010", // New field
    },
  ];
  const [productsList,setproductsList] = useState(productsTotal);
  const Searchsample = (e) => {
    var searchValue = e.target.value;
    const results = [];
    for (const key in productsTotal) {
        if (productsTotal.hasOwnProperty(key)) {
            const entries = Object.entries(productsTotal[key]);

            for (const [field, value] of entries) {
                if (value.toString().toLowerCase().includes(searchValue.toLowerCase())) {
                    results.push({ key, ...productsTotal[key] });
                    break; // Stop searching once a match is found for this person
                }
            }
        }
    }
    setproductsList(results);
    return results;
  };
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Sampling List</h2>
              {/* <p>Lorem ipsum dolor sit amet.</p> */}
            </div>
            <div>
              <a className="btn btn-light rounded font-md mx-2" href="#">
                Export
              </a>
              <a className="btn btn-light rounded font-md mx-2" href="#">
                Import
              </a>
              <Link
                className="btn btn-primary btn-sm rounded"
                to="/pageformproduct"
              >
                Create new
              </Link>
            </div>
          </div>
          <div className="card mb-4">
            <header className="card-header">
              <div className="row align-items-center">
                {/* <div className="col col-check flex-grow-0">
                  <div className="form-check ms-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                  </div>
                </div> */}
                <div className="col-md-2 col-12 mb-md-0 mb-3">
                  <select className="form-select">
                    <option selected="">Search by</option>
                    <option>Order Num</option>
                    <option>Ref Num</option>
                    <option>Color</option>
                    <option>Material</option>
                    <option>Prints</option>
                    <option>Manufacturer</option>
                    <option>Buyer</option>
                  </select>
                </div>
                <div className="col-md-3 col-6  me-auto">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search..."
                    onChange={Searchsample}
                  />
                </div>
                <div className="col-md-2 col-6">
                  <select className="form-select">
                    <option selected="">Status</option>
                    <option>Delivered</option>
                    <option>Shipped</option>
                    <option>Inprogress</option>
                    <option>Yet to Start</option>
                  </select>
                </div>
              </div>
            </header>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Sample Name/Ref Num</th>
                      <th>Buyer</th>
                      <th>Manufacturer</th>
                      <th>Rack</th>
                      <th>Order ID</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Created Date</th>
                      <th className="text-end"> Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productsList.map((product) => (
                      <tr key={product.ref_id}>
                        <td>
                          <a className="itemside" href="#">
                            <div className="left">
                              <img
                                className="img-sm img-thumbnail"
                                src={product.img}
                                alt={product.product_name}
                              />
                            </div>
                            <div className="info">
                              <h6 className="mb-0">{product.product_name}</h6>
                              <small className="text-muted">
                                Ref ID: {product.ref_id}
                              </small>
                            </div>
                          </a>
                        </td>
                        <td>
                          <a className="itemside" href="#">
                            <div className="info pl-3">
                              <h6 className="mb-0 title">{product.buyer}</h6>
                              <small className="text-muted">
                                Buyer ID: {product.buyer_id}
                              </small>
                            </div>
                          </a>
                        </td>
                        <td>
                          <a className="itemside" href="#">
                            <div className="info pl-3">
                              <h6 className="mb-0 title">
                                {product.manufacturer}
                              </h6>
                              <small className="text-muted">
                                Manufacturer ID: {product.seller_id}
                              </small>
                            </div>
                          </a>
                        </td>
                        <td>Rack-1{product.order_id}</td>
                        <td>{product.order_id}</td>
                        <td>{product.type}</td>
                        <td>
                          <span
                            className={
                              product.status == "Delivered"
                                ? "badge rounded-pill alert-success"
                                : product.status == "Shipped"
                                ? "badge rounded-pill alert-primary"
                                : "badge rounded-pill alert-warning"
                            }
                          >
                            {product.status}
                          </span>
                        </td>
                        <td>{product.createdate}</td>
                        <td className='text-end' style={{whiteSpace:"nowrap"}}>
                          <div className="dropdown">
                            <a
                              className="btn btn-light rounded btn-sm font-sm"
                              href="#"
                              data-bs-toggle="dropdown"
                            >
                              <i className="material-icons md-more_horiz"></i>
                            </a>
                            <div className="dropdown-menu">
                              <a className="dropdown-item" href="#">
                                Change Status
                              </a>
                              <a className="dropdown-item" href="#">
                                Ship Sample
                              </a>
                              <a className="dropdown-item" href="#">
                                Create PO
                              </a>
                              <a className="dropdown-item text-danger" href="#">
                                Delete
                              </a>
                            </div>
                          </div>
                          <Link
                            className="btn btn-md rounded font-sm"
                            to="/samplingdetail"
                          >
                            View Details
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="pagination-area mt-30 mb-50">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-start">
                <li className="page-item Yet to Start">
                  <a className="page-link" href="#">
                    01
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    02
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    03
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link dot" href="#">
                    ...
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    16
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    <i className="material-icons md-chevron_right"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default Pageproductslist;

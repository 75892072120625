import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/layouts/Home';
import Pageordersdetail from './components/layouts/Pageordersdetail';
import "../src/assets/css/style.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Pageorderstracking from './components/layouts/Pageorderstracking';
import Pageproductslist from './components/layouts/Pageproductslist';
import Pagereviews from './components/layouts/Pagereviews';
import Pagesellerdetail from './components/layouts/Pagesellerdetail';
import Pagesellerslist from './components/layouts/Pagesellerslist';
import Pagesettings from './components/layouts/Pagesettings';
import Pagetransactions from './components/layouts/Pagetransactions';
import Pagetransactiondetails from './components/layouts/Pagetransactiondetails';
import Pageaccountlogin from './components/layouts/Pageaccountlogin';
import Pageaccountregister from './components/layouts/Pageaccountregister';
import Pageblank from './components/layouts/Pageblank';
import Pagebrands from './components/layouts/Pagebrands';
import Pagecategories from './components/layouts/Pagecategories';
import Pageerror from './components/layouts/Pageerror';
import Pageformproduct from './components/layouts/Pageformproduct';
import Pageformprofit from './components/layouts/Pageformprofit';
import Pageinvoice from './components/layouts/Pageinvoice';
import Pageorders from './components/layouts/Pageorders';
import PageBuyerslist from './components/layouts/Pagebuyerslist';
import Pagesellersadd from './components/layouts/Pagesellersadd';
import Pagebuyersadd from './components/layouts/Pagebuyersadd';
import Pagebuyerdetail from './components/layouts/Pagebuyerdetail';
import Pageformshipping from './components/layouts/Pageformshipping';
import Pageformpurchaseorder from './components/layouts/Pageformpurchaseorder';
import Pageinvoicelist from './components/layouts/Pageinvoicelist';
import Pageforminvoice from './components/layouts/Pageforminvoice';
import Pageinvoicedetail from './components/layouts/Pageinvoicedetail';
import ReportInvoice from './components/layouts/ReportInvoice';
import Pagepurchase from './components/layouts/Pagepurchase';
import Reportshipment from './components/layouts/Reportshipment';
import SampelingDev from './components/layouts/SampelingDev';
import EnquiryList from './components/layouts/EnquiryList';
import EnquiryCreate from './components/layouts/EnquiryCreate';
import SamplingDetailPage from './components/layouts/SamplingDetailPage';
import Pageracklist from './components/layouts/Pageracklist';
import RackAdd from './components/layouts/RackAdd';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/home' element={<Home/>}></Route>
          <Route path='/pageordersdetail' element={<Pageordersdetail/>}></Route>
          <Route path='/samplingdetail' element={<SamplingDetailPage/>}></Route>
          <Route path='/pageinvoicedetail' element={<Pageinvoicedetail/>}></Route>
          <Route path='/pageorderstracking' element={<Pageorderstracking/>}></Route>
          <Route path='/pageproductslist' element={<Pageproductslist/>}></Route>
          <Route path='/pagesamplingdev' element={<SampelingDev/>}></Route>
          <Route path='/enquirylist' element={<EnquiryList/>}></Route>
          <Route path='/pageinvoicelist' element={<Pageinvoicelist/>}></Route>
          <Route path='/pageinvoiceform' element={<Pageforminvoice/>}></Route>
          <Route path='/pagereviews' element={<Pagereviews/>}></Route>
          <Route path='/pagesellerdetail' element={<Pagesellerdetail/>}></Route>
          <Route path='/pagebuyerdetail' element={<Pagebuyerdetail/>}></Route>
          <Route path='/pagesellerslist' element={<Pagesellerslist/>}></Route>
          <Route path='/pagesellersadd' element={<Pagesellersadd/>}></Route>
          <Route path='/pagebuyerslist' element={<PageBuyerslist/>}></Route>
          <Route path='/pagebuyersadd' element={<Pagebuyersadd/>}></Route>
          <Route path='/pagesettings' element={<Pagesettings/>}></Route>
          <Route path='/pagetransactions' element={<Pagetransactions/>}></Route>
          <Route path='/pagetransactiondetails' element={<Pagetransactiondetails/>}></Route>
          <Route path='/' element={<Pageaccountlogin/>}></Route>
          <Route path='/pageaccountregister' element={<Pageaccountregister/>}></Route>
          <Route path='/pageblank' element={<Pageblank/>}></Route>
          <Route path='/pagebrands' element={<Pagebrands/>}></Route>
          <Route path='/pagecategories' element={<Pagecategories/>}></Route>
          <Route path='/pageerror' element={<Pageerror/>}></Route>
          <Route path='/pageformproduct' element={<Pageformproduct/>}></Route>
          <Route path='/enquiryadd' element={<EnquiryCreate/>}></Route>
          <Route path='/pageformprofit' element={<Pageformprofit/>}></Route>
          <Route path='/pageformshipping' element={<Pageformshipping/>}></Route>
          <Route path='/pageinvoice' element={<Pageinvoice/>}></Route>
          <Route path='/pageorders' element={<Pageorders/>}></Route>
          <Route path='/pageformpurchaseorder' element={<Pageformpurchaseorder/>}></Route>
          <Route path='/invoicereport' element={<ReportInvoice/>}></Route>
          <Route path='/purchasereport' element={<Pagepurchase/>}></Route>
          <Route path='/shipmentreport' element={<Reportshipment/>}></Route>
          <Route path='/rackmanagement' element={<Pageracklist/>}></Route>
          <Route path='/rackadd' element={<RackAdd/>}></Route>
          
          
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

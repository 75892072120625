import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import brand1 from "../../assets/imgs/brands/brand-1.jpg";
import brand2 from "../../assets/imgs/brands/brand-2.jpg";
import brand3 from "../../assets/imgs/brands/brand-3.jpg";
import brand4 from "../../assets/imgs/brands/brand-4.jpg";
import brand5 from "../../assets/imgs/brands/brand-5.jpg";
import brand6 from "../../assets/imgs/brands/brand-6.jpg";
import brand7 from "../../assets/imgs/brands/brand-7.jpg";
import brand8 from "../../assets/imgs/brands/brand-8.jpg";
import brand9 from "../../assets/imgs/brands/brand-9.jpg";
import brand10 from "../../assets/imgs/brands/brand-10.jpg";
import brand11 from "../../assets/imgs/brands/brand-11.jpg";
import brand12 from "../../assets/imgs/brands/brand-12.jpg";
import brand13 from "../../assets/imgs/brands/brand-13.jpg";
import brand14 from "../../assets/imgs/brands/brand-14.jpg";
import brand15 from "../../assets/imgs/brands/brand-15.jpg";
import brand16 from "../../assets/imgs/brands/brand-16.jpg";
import brand17 from "../../assets/imgs/brands/brand-17.jpg";
import brand18 from "../../assets/imgs/brands/brand-18.jpg";

function Pagebrands(){
    return(
        <>
        
        <Sidebar/>
        <main className="main-wrap">
        <Header/>

        <section className="content-main">
        <div className="content-header">
          <div>
            <h2 className="content-title card-title">Brand</h2>
            <p>Brand and vendor management</p>
          </div>
          <div><a className="btn btn-primary" href="#"><i className="text-muted material-icons md-post_add"></i>Add New Brand</a></div>
        </div>
        <div className="card mb-4">
          <header className="card-header">
            <Row className="gx-3">
              <Col lg={4} className="mb-lg-0 mb-15 me-auto">
                <input className="form-control" type="text" placeholder="Search..."/>
              </Col>
              <Col lg={2} sm={6}>
                <div className="custom_select">
                  <select className="form-select select-nice">
                    <option selected="">Categories</option>
                    <option>Technology</option>
                    <option>Fashion</option>
                    <option>Home Decor</option>
                    <option>Healthy</option>
                    <option>Travel</option>
                    <option>Auto-car</option>
                  </select>
                </div>
              </Col>
              <Col lg={2} sm={6}>
                <input className="form-control" type="date" name=""/>
              </Col>
            </Row>
          </header>
          <div className="card-body">
            <Row className="gx-3">
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand1} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Cardinal</h6><a href="#"> 398 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand2} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">BirdFly</h6><a href="#"> 13 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand3} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Cocorico</h6><a href="#"> 13 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand4} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Yogilist</h6><a href="#"> 87 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand5} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Acerie</h6><a href="#"> 10 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand6} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Shivakin</h6><a href="#"> 398 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand7} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Acera</h6><a href="#"> 398 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand8} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Lion electronics</h6><a href="#"> 398 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand9} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">TwoHand</h6><a href="#"> 398 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand10} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Kiaomin</h6><a href="#"> 398 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand11} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Nokine</h6><a href="#"> 398 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand12} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Company name</h6><a href="#"> 13 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand13} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Company name</h6><a href="#"> 398 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand14} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Company name</h6><a href="#"> 13 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand15} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Company name</h6><a href="#"> 398 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand16} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Company name</h6><a href="#"> 13 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand17} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Company name</h6><a href="#"> 13 items</a>
                  </figcaption>
                </figure>
              </Col>
              <Col xl={2} lg={3} md={4} sm={6}>
                <figure className="card border-1">
                  <div className="card-header bg-white text-center"><img className="img-fluid" height="76" src={brand18} alt="Logo"/></div>
                  <figcaption className="card-body text-center">
                    <h6 className="card-title m-0">Company name</h6><a href="#"> 13 items</a>
                  </figcaption>
                </figure>
              </Col>
            </Row>
          </div>
        </div>
      </section>

        <Footer/>
        </main>
        </>
    );
}
export default Pagebrands;
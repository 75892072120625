function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className="main-footer font-xs">
        <div className="row pb-30 pt-15">
          <div className="col-sm-6">
            <p>&copy; {currentYear}, Apricot Services, All rights reserved</p>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;

import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import uploads from "../../assets/imgs/theme/upload.svg";
import { Link } from "react-router-dom";

function Pagesellersadd() {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <Row>
            <Col md={9}>
              <div className="content-header">
                <h2 className="content-title">Add New Manufacturer</h2>
                <div>
                  {/* <button className="btn btn-light rounded font-sm mr-5 text-body hover-up">Save to draft</button> */}
                  <Link to="/pagesellerslist" className="btn btn-md rounded font-sm hover-up">
                    Save
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="card mb-4">
                <div className="card-header">
                  <h4>Basic</h4>
                </div>
                <div className="card-body">
                  <form>
                    {/* <div className="mb-4">
                    <label className="form-label" for="product_name">Manufacturer name</label>
                    <input className="form-control" id="product_name" type="text" placeholder="Type here"/>
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Full description</label>
                    <textarea className="form-control" placeholder="Type here" rows="4"></textarea>
                  </div> */}
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label">
                            Manufacturer name
                          </label>
                          <div className="row gx-2"></div>
                          <input
                            className="form-control"
                            placeholder="Type Here"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label">Email</label>
                          <div className="row gx-2"></div>
                          <input
                            className="form-control"
                            placeholder="Type Here"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label">Contact person</label>
                          <div className="row gx-2"></div>
                          <input
                            className="form-control"
                            placeholder="Type Here"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <label className="form-label">Contact number</label>
                        <div className="row gx-2"></div>
                        <input
                          className="form-control"
                          placeholder="Type Here"
                          type="text"
                        />
                      </div>
                    </div>
                    {/* <div className="mb-4">
                    <label className="form-label">Tax rate</label>
                    <input className="form-control" id="product_name" type="text" placeholder="%"/>
                  </div> */}
                    <label className="form-check mb-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <span className="form-check-label">
                        {" "}
                        Trusted Manufacturer
                      </span>
                    </label>
                  </form>
                </div>
              </div>
              <div className="card mb-4">
                <div className="card-header">
                  <h4>Location</h4>
                </div>
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label" for="product_name">
                            Address 1
                          </label>
                          <input
                            className="form-control"
                            id="product_name"
                            type="text"
                            placeholder="inch"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label" for="product_name">
                            Address 2
                          </label>
                          <input
                            className="form-control"
                            id="product_name"
                            type="text"
                            placeholder="inch"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label" for="product_name">
                            City
                          </label>
                          <input
                            className="form-control"
                            id="product_name"
                            type="text"
                            placeholder="inch"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label" for="product_name">
                            State
                          </label>
                          <input
                            className="form-control"
                            id="product_name"
                            type="text"
                            placeholder="inch"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label" for="product_name">
                            Zipcode
                          </label>
                          <input
                            className="form-control"
                            id="product_name"
                            type="text"
                            placeholder="inch"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card mb-4">
                <div className="card-header">
                  <h4>Logo</h4>
                </div>
                <div className="card-body">
                  <div className="input-upload">
                    <img src={uploads} alt="" />
                    <input className="form-control" type="file" />
                  </div>
                </div>
              </div>
              <div className="card mb-4">
                <div className="card-header">
                  <h4>Notes</h4>
                </div>
                <div className="card-body">
                  <div className="row gx-2">
                    <div className="mb-4">
                      <label className="form-label">Description about Manufacturer</label>
                      <textarea
                        className="form-control"
                        placeholder="Type here"
                        rows="4"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default Pagesellersadd;
